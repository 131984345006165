import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withStyles } from '@material-ui/core';

import CostFormIcon from '@assets/Icons/CostForm';
import MultiPageIcon from '@assets/Icons/MultiPage';
import SinglePageIcon from '@assets/Icons/SinglePage';
import GridXlsxExport from 'dataGrid/components/GridXLSXExport';

import {
    downloadSamplesAsExcel,
    downloadSamplesAsMultiPage,
    downloadSamplesAsSinglePage,
    downloadSamplesAsCostForm
} from 'sampling/redux.actions';
import {
    downloadSamplesAsExcelLoadingSelector,
    downloadSamplesAsMultiPageLoadingSelector,
    downloadSamplesAsSinglePageLoadingSelector,
    downloadSamplesAsCostFormLoadingSelector
} from 'sampling/redux.selectors';
import PDF from './PDF';
import ExportTitleAndIcon from './ExportTitleAndIcon';

const styles = ({ spacing }) => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: `${spacing.unit * 4}px`
    }
});

const mapStateToProps = state => ({
    exportAsExcelloading: downloadSamplesAsExcelLoadingSelector(state),
    exportAsMultiPageLoading: downloadSamplesAsMultiPageLoadingSelector(state),
    exportAsSinglePageLoading: downloadSamplesAsSinglePageLoadingSelector(
        state
    ),
    exportAsCostFormLoading: downloadSamplesAsCostFormLoadingSelector(state)
});

const mapDispatchToProps = (dispatch, { ...payload }) => ({
    handleXLDownload: () => dispatch(downloadSamplesAsExcel(payload)),
    handleMultiPageDownload: () =>
        dispatch(downloadSamplesAsMultiPage(payload)),
    handleSinglePageDownload: () =>
        dispatch(downloadSamplesAsSinglePage(payload)),
    handleCostFormDownload: () => dispatch(downloadSamplesAsCostForm(payload))
});

const ExportOptions = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles)
)(
    ({
        classes,
        exportAsExcelloading,
        exportAsMultiPageLoading,
        handleXLDownload,
        handleMultiPageDownload,
        exportAsSinglePageLoading,
        handleSinglePageDownload,
        exportAsCostFormLoading,
        handleCostFormDownload,
        selectedRows,
        data
    }) => {
        return (
            <div className={classes.root}>
                <GridXlsxExport
                    title='XLSX'
                    loading={exportAsExcelloading}
                    onClick={handleXLDownload}
                />
                <PDF data={data} selectedRows={selectedRows} />
                <ExportTitleAndIcon
                    title='Multi page'
                    Icon={MultiPageIcon}
                    onClick={handleMultiPageDownload}
                    disabled={exportAsMultiPageLoading}
                />
                <ExportTitleAndIcon
                    title='Single page'
                    Icon={SinglePageIcon}
                    onClick={handleSinglePageDownload}
                    disabled={exportAsSinglePageLoading}
                />
                <ExportTitleAndIcon
                    title='Cost form'
                    Icon={CostFormIcon}
                    onClick={handleCostFormDownload}
                    disabled={exportAsCostFormLoading}
                />
            </div>
        );
    }
);

export { ExportOptions as default, ExportOptions };
