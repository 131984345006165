import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';

import { withPDFStyles } from '@libComponents/ReactPDF';
import { formatDateToShort } from '@baseComponents/Date/normalizeDate';

import Image from '../Image';
import ColumnContainer from '../ColumnContainer';
import Row from '../Row';
import Divider from '../Divider';

const propTypes = {
    item: PropTypes.object.isRequired,
    isLast: PropTypes.bool.isRequired
};

const styles = ({ spacing }) => ({
    container: {
        width: '100%',
        paddingTop: spacing.unit * 3,
        paddingBottom: spacing.unit * 3,
        flexDirection: 'row'
    },
    imageContainer: {
        width: '30%',
        maxHeight: spacing.unit * 25,
        marginRight: spacing.unit * 2.5
    },
    contentContainer: {
        width: '70%',
        flexDirection: 'row'
    }
});

const Item = withPDFStyles(styles)(
    ({
        classes: c,
        item: {
            frontImage,
            sampleRef,
            department,
            sampleOrderStatus,
            sampleStatus,
            specRef,
            devNo,
            graphicDescription,
            graphicRef,
            designer,
            fabrication,
            dateRequested,
            dateModified,
            targetSentDate,
            sampleEta,
            actualSendDate,
            factory,
            fabBookDate,
            fabRcvDate,
            currier,
            trackingRef
        },
        isLast
    }) => {
        return (
            <View>
                <View style={c.container}>
                    <View style={c.imageContainer}>
                        <Image url={frontImage} label='Image' />
                    </View>
                    <View style={c.contentContainer}>
                        <ColumnContainer styles={{ width: '60%' }}>
                            <Row header='Sample Ref' value={sampleRef} />
                            <Row header='Department' value={department} />
                            <Row header='Status' value={sampleOrderStatus} />
                            <Row header='Sample Status' value={sampleStatus} />
                            <Row header='Spec' value={specRef} />
                            <Row header='Dev No' value={devNo} />
                            <Row
                                header='Graphics Desc'
                                value={graphicDescription}
                            />
                            <Row header='Design Ref' value={graphicRef} />
                            <Row header='Designer' value={designer} />
                            <Row header='Fabrication' value={fabrication} />
                        </ColumnContainer>
                        <ColumnContainer styles={{ width: '40%' }}>
                            <Row
                                header='1st Req Dt'
                                value={formatDateToShort(dateRequested)}
                            />
                            <Row
                                header='Mod Dt'
                                value={formatDateToShort(dateModified)}
                            />
                            <Row
                                header='Target Snt Dt'
                                value={formatDateToShort(targetSentDate)}
                            />
                            <Row
                                header='Sam ETA'
                                value={formatDateToShort(sampleEta)}
                            />
                            <Row
                                header='Act Snt Dt'
                                value={formatDateToShort(actualSendDate)}
                            />
                            <Row header='Factory' value={factory} />
                            <Row
                                header='Fabric Booking Date'
                                value={formatDateToShort(fabBookDate)}
                            />
                            <Row
                                header='Fabric Rcv Date'
                                value={formatDateToShort(fabRcvDate)}
                            />
                            <Row header='Currier' value={currier} />
                            <Row header='Trac. Ref' value={trackingRef} />
                        </ColumnContainer>
                    </View>
                </View>
                {!isLast && <Divider />}
            </View>
        );
    }
);

Item.propTypes = propTypes;

export default Item;
