import { select, all } from 'redux-saga/effects';

import { renameProperties } from '@libs/object';
import { composeDevelopmentNumber } from '@libs/publishWorkers';
import { deserializeDate } from '@baseComponents/Date/normalizeDate';
import { userIdSelector } from 'auth/redux.selectors';

import { developmentTypesSelector } from 'common/redux.selectors';

import { formValueSelector } from 'redux-form';
import { FORM_NAME } from '../../../Print/common';

export default function*({ mode, id, sampleReference }) {
    const [
        userId,
        {
            developmentNumber,
            fabrics,
            boughtDetails,
            dateRequested,
            dateRequiredBy,
            ...values
        },
        developmentTypes
    ] = yield all([
        select(userIdSelector),
        select(
            formValueSelector(FORM_NAME),
            'gender',
            'age',
            'category',
            'department',
            'style',
            'developmentNumber',
            'cad',
            'version',
            'cadDescription',
            'country',
            'requestType',
            'fitOption',
            'dateRequested',
            'dateRequiredBy',
            'sendToEcho',
            'sendToBuyer',
            'buyer',
            'sendToLicensor',
            'photoApproval',
            'specReference',
            'specDescription',
            'additionalComments',
            'artworkLinks',
            'numberOfColours',
            'fabrics',
            'boughtDetails'
        ),
        select(developmentTypesSelector, 'Print')
    ]);

    const developmentNo = composeDevelopmentNumber(
        developmentTypes,
        developmentNumber,
        mode
    );

    return renameProperties(
        {
            ...values,
            userId,
            id,
            sampleReference,
            developmentNo,
            requestedDate: deserializeDate(dateRequested),
            sampleRequiredDate: deserializeDate(dateRequiredBy),
            fabrics: fabrics.map(fabric =>
                renameProperties(fabric, {
                    fabricFor: 'fabricForId',
                    fabricType: 'fabricTypeId',
                    composition: 'compositionId',
                    finishedGsm: 'gsmId',
                    dyeType: 'dyeTypeId',
                    specialFinish: 'specialFinishId'
                })
            ),
            boughts: boughtDetails.map(({ id, boughtSampleRef }) => ({
                id,
                boughtRef: boughtSampleRef
            }))
        },
        {
            sampleReference: 'sampleRef',
            cad: 'cadRef',
            version: 'cadVersion',
            gender: 'genderId',
            age: 'ageId',
            category: 'categoryId',
            department: 'departmentId',
            style: 'styleId',
            country: 'countryId',
            requestType: 'requestTypeId',
            fitOption: 'fitId',
            sendToEcho: 'ukSizeId',
            sendToBuyer: 'buyerSizeId',
            buyer: 'departmentContactPerson',
            sendToLicensor: 'licensorSizeId',
            photoApproval: 'isSampleRequired',
            specReference: 'specRef',
            specDescription: 'garmentDescription',
            cadDescription: 'graphicDescription',
            additionalComments: 'specComments'
        }
    );
}
