import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { persistReducer } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import createCompressor from 'redux-persist-transform-compress';

import reducers from './reducer';

const compressor = createCompressor();
const persistConfig = {
    key: 'root',
    storage: storage,
    transforms: [
        encryptTransform({
            secretKey:
                process.env.REACT_APP_DATA_ENCRYPTION_KEY || 'secret-key-123'
        }),
        compressor
    ],
    whitelist: [
        'auth',
        'bought',
        'trim',
        'common',
        'datasets',
        'navigation',
        'cadUpload',
        'sampling',
        'utilities',
        'form',
        'gridView',
        'cadDesignerReport',
        'orders',
        'qualityControl',
        'dataGrid',
        'administrator',
        'fabric'
    ],
    stateReconciler: autoMergeLevel2
};

export default persistReducer(persistConfig, reducers);
