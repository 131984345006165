import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, formValueSelector, reduxForm } from 'redux-form';

import { Divider, Grid, withStyles } from '@material-ui/core';

// Libs
import ContentContainer from '@libComponents/ContentContainer';
import { Checkbox, Input, GridItem } from '@formComponents';
import { formListener } from 'app/ReduxGate/store/middleware';
import { fetchDatasets } from '@libs/datasets';
import { requestedDateAfterNewImplementation } from '@baseComponents/Date';

// Other domain
import {
    SelectGenders,
    SelectAges,
    SelectCategories,
    SelectStyles
} from 'common/components';
import { DEVELOPMENT_TYPES } from 'common/redux.datasets';

// Local
import SelectVersions from './SelectVersions';
import Preview from './Preview';
import asyncValidate from './asyncValidate';
import HeaderAdornment from '../HeaderAdornment';
import {
    SamplePaginatedSelect,
    SelectCountries,
    SelectRetailers,
    SelectPrintSampleCads,
    DesignSource,
    Retailer,
    SelectDepartments
} from '../../../components';
import { FORM_NAME, LABELS } from '../../common';
import {
    GET_VERSIONS,
    SET_HAS_MANUAL_REF_CHILDREN
} from '../../../redux.actionTypes';
import {
    getCopyPrintData,
    setFabricOptionChildren
} from '../../../redux.actions';
import { GET_SAMPLE_SIZES, PRINT_COPY_SAMPLES } from '../../../redux.datasets';
import { previewLoadingSelector } from '../../../redux.selectors';
import { usePrintState } from '../../usePrint';

const styles = ({ spacing, typography, breakpoints }) => ({
    container: {
        width: '64%',
        paddingTop: spacing.unit * 2,
        paddingBottom: spacing.unit * 6,
        [breakpoints.down(1920)]: {
            width: '80%'
        },
        [breakpoints.down(1280)]: {
            width: '90%'
        }
    },
    checkBoxes: {
        marginTop: spacing.unit * 2
    },
    divider: {
        marginTop: spacing.unit * 3,
        marginBottom: spacing.unit * 1.5
    },
    checkboxLabel: { fontSize: typography.caption.fontSize }
});

const mapState = state => {
    const values = formValueSelector(FORM_NAME)(
        state,
        'hasManualReference',
        'hasCopyReference',
        'copyReference',
        'hasFabricOption',
        'dateRequested',
        'hasOrdered'
    );
    return {
        ...values,
        versionLoading: previewLoadingSelector(state)
    };
};

const mapDispatch = {
    fetchDatasets
};

const mapInputs = {
    cad: ({ payload, meta }) => ({ type: GET_VERSIONS, payload, meta }),
    hasManualReference: ({ payload }) => ({
        type: SET_HAS_MANUAL_REF_CHILDREN,
        payload,
        meta: { formName: FORM_NAME }
    }),
    manualReference: ({ payload }) => {
        if (payload) return change(FORM_NAME, 'manualReferenceAsync', '');
    },
    hasCopyReference: ({ payload }) => {
        if (!payload) return change(FORM_NAME, 'copyReference', '');
    },
    copyReference: ({ payload }) => getCopyPrintData(payload),
    hasFabricOption: ({ payload }) =>
        setFabricOptionChildren({
            formName: FORM_NAME,
            hasFabricOption: payload
        })
};

const Setup = compose(
    connect(
        mapState,
        mapDispatch
    ),
    reduxForm({
        form: FORM_NAME,
        destroyOnUnmount: false,
        asyncValidate,
        asyncBlurFields: ['manualReference']
    }),
    formListener(mapInputs),
    withStyles(styles)
)(
    ({
        classes: c,
        hasManualReference,
        hasCopyReference,
        hasOrdered,
        fetchDatasets,
        versionLoading,
        hasFabricOption,
        dateRequested
    }) => {
        const { setupLocked, isCadMandatory, mode } = usePrintState();
        const [previewVersion, setPreviewVersion] = useState(null);
        const isNewFieldsDisabled = requestedDateAfterNewImplementation(
            dateRequested
        );

        useEffect(() => {
            fetchDatasets([GET_SAMPLE_SIZES, DEVELOPMENT_TYPES]);
        }, []);

        return (
            <ContentContainer
                title={LABELS.setup}
                AdornmentComponent={<HeaderAdornment />}
            >
                <div className={c.container}>
                    {/* Checkboxes wrapper */}
                    {mode === 'new' && (
                        <Fragment>
                            <Grid container className={c.checkBoxes}>
                                <GridItem md={4}>
                                    <Checkbox
                                        name='hasFabricOption'
                                        label='Colour option'
                                        styles={{
                                            label: c.checkboxLabel
                                        }}
                                        disabled={!!setupLocked}
                                    />
                                </GridItem>
                                <GridItem md={4}>
                                    <Checkbox
                                        name='hasCopyReference'
                                        label='Copy data from another sample request'
                                        styles={{
                                            label: c.checkboxLabel
                                        }}
                                        disabled={
                                            !!setupLocked || !!hasFabricOption
                                        }
                                    />
                                </GridItem>
                                <GridItem md={4}>
                                    <Checkbox
                                        name='hasManualReference'
                                        label='I want to give this a manual reference'
                                        styles={{
                                            label: c.checkboxLabel
                                        }}
                                        disabled={
                                            !!setupLocked || !!hasFabricOption
                                        }
                                    />
                                </GridItem>
                                <GridItem md={4} />
                                <GridItem md={4}>
                                    {hasCopyReference &&
                                        (setupLocked ? (
                                            <Input
                                                name='copyReference'
                                                placeholder=' '
                                                required
                                                disabled={true}
                                            />
                                        ) : (
                                            <SamplePaginatedSelect
                                                name='copyReference'
                                                entity={PRINT_COPY_SAMPLES}
                                                isCancellable={false}
                                                required
                                            />
                                        ))}
                                </GridItem>
                                <GridItem md={4}>
                                    {hasManualReference && (
                                        <Input
                                            name='manualReference'
                                            placeholder='Enter manual reference'
                                            required
                                            disabled={!!setupLocked}
                                            autoFocus={!hasFabricOption}
                                        />
                                    )}
                                </GridItem>
                            </Grid>
                            <Divider className={c.divider} />
                        </Fragment>
                    )}

                    {/* Cad data and image wrapper */}

                    <Grid container>
                        <GridItem md={8}>
                            <Grid container>
                                <GridItem md={6}>
                                    {setupLocked || hasFabricOption ? (
                                        <Input
                                            name='cad'
                                            label='Select CAD'
                                            placeholder=' '
                                            disabled
                                            required={isCadMandatory}
                                        />
                                    ) : (
                                        <SelectPrintSampleCads
                                            required={isCadMandatory}
                                        />
                                    )}
                                </GridItem>
                                <GridItem md={6}>
                                    {hasOrdered ? (
                                        <Input
                                            name='version'
                                            label='Version no'
                                            placeholder=' '
                                            disabled
                                            required={isCadMandatory}
                                            loading={versionLoading}
                                        />
                                    ) : (
                                        <SelectVersions
                                            required={isCadMandatory}
                                            handleHover={setPreviewVersion}
                                        />
                                    )}
                                </GridItem>
                                <GridItem md={6}>
                                    <Input
                                        name='cadDescription'
                                        label='Graphic description'
                                        placeholder='Graphic description'
                                        required={isCadMandatory}
                                    />
                                </GridItem>
                                <GridItem md={6}>
                                    <DesignSource form={FORM_NAME} />
                                </GridItem>
                                <GridItem md={6}>
                                    {setupLocked || hasFabricOption ? (
                                        <Retailer form={FORM_NAME} />
                                    ) : (
                                        <SelectRetailers required />
                                    )}
                                </GridItem>
                                <GridItem md={6}>
                                    <SelectGenders
                                        required
                                        disabled={isNewFieldsDisabled}
                                    />
                                </GridItem>
                                <GridItem md={6}>
                                    <SelectAges
                                        required
                                        disabled={isNewFieldsDisabled}
                                    />
                                </GridItem>
                                <GridItem md={6}>
                                    <SelectCategories
                                        required
                                        disabled={isNewFieldsDisabled}
                                    />
                                </GridItem>
                                <GridItem md={6}>
                                    <SelectDepartments
                                        required
                                        disabled={isNewFieldsDisabled}
                                    />
                                </GridItem>
                                <GridItem md={6}>
                                    <SelectStyles
                                        required
                                        disabled={isNewFieldsDisabled}
                                    />
                                </GridItem>
                                <GridItem md={6}>
                                    <SelectCountries required />
                                </GridItem>
                            </Grid>
                        </GridItem>
                        <GridItem md={4}>
                            <Preview previewVersion={previewVersion} />
                        </GridItem>
                    </Grid>
                </div>
            </ContentContainer>
        );
    }
);

export default Setup;
