import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
    getFormAsyncErrors,
    getFormSubmitErrors,
    getFormSyncErrors,
    getFormValues,
    isSubmitting,
    initialize as formInitialize
} from 'redux-form';

import { Paper, withStyles } from '@material-ui/core';

import { useValidate, useNext } from '@libHooks/useValidate';
import useStepperContainer from '@libHooks/useStepperContainer';
import Button from '@libComponents/Button';
import { withModalProvider, withModal } from '@libComponents/Modal';
import { isStyleBottomSelector } from 'common/redux.selectors';

import { ShapeProvider } from './useShape';
import { useHandles } from './hooks';
import { FORM_NAME, INITIAL_VALUES } from './common';
import useSchema from './content/useSchema';
import {
    destroyShapeForm,
    shapePublish,
    shapeSaveDraft,
    shapeHandover,
    shapeReferenceGenerate
} from '../redux.actions';
import Preview from './components/Preview';

import {
    referenceLoadingSelector,
    isSealedFitOptionSelector
} from 'sampling/redux.selectors';

const styles = ({ spacing, palette, shadows, typography }) => ({
    container: {
        backgroundColor: palette.grey[100],
        position: 'relative',
        boxShadow: shadows[0]
    },
    button: {
        position: 'absolute',
        height: spacing.unit * 5.5,
        width: spacing.unit * 48,
        fontSize: typography.subtitle1.fontSize,
        bottom: 0,
        right: '50%',
        transform: 'translate(50%, 50%)',
        zIndex: 4
    }
});

const mapState = state => {
    const submitErrors = getFormSubmitErrors(FORM_NAME)(state);
    const asyncErrors = getFormAsyncErrors(FORM_NAME)(state);
    const syncErrors = getFormSyncErrors(FORM_NAME)(state);
    const formValues = getFormValues(FORM_NAME)(state) || {};

    return {
        formValues,
        formErrors: { ...submitErrors, ...asyncErrors, ...syncErrors },
        formSubmitting: isSubmitting(FORM_NAME)(state),
        isStyleBottom: isStyleBottomSelector(state, formValues.style),
        sampleRefLoading: referenceLoadingSelector(state),
        isSealed: isSealedFitOptionSelector(state)
    };
};

const mapDispatch = {
    destroyShapeForm,
    publish: shapePublish,
    saveDraft: shapeSaveDraft,
    handover: shapeHandover,
    shapeReferenceGenerate,
    formInitialize
};

const ShapeContainer = compose(
    withModalProvider,
    withModal({
        handleModal: Preview
    }),
    connect(
        mapState,
        mapDispatch
    ),
    withStyles(styles)
)(
    ({
        classes: c,
        children,
        stepReducer: [getState = () => ({}), dispatch = () => {}],
        formValues = {},
        formErrors = {},
        formSubmitting,
        mode,
        destroyShapeForm,
        publish,
        saveDraft,
        handover,
        handleModal,
        handleReduxFormSubmit,
        isStyleBottom,
        shapeReferenceGenerate,
        formInitialize,
        sampleRefLoading,
        isSealed
    }) => {
        const state = getState();
        const { activeStep, contents } = state;

        const {
            sampleReference,
            manualReference,
            setupLocked: setupLockedFromForm
        } = formValues;
        const shouldSampleRefGenerate = useMemo(() => {
            if (
                activeStep === 1 &&
                mode === 'new' &&
                (!sampleReference || sampleReference === manualReference)
            ) {
                return true;
            }
        }, [activeStep, mode, sampleReference, manualReference]);
        useEffect(() => {
            if (shouldSampleRefGenerate) {
                shapeReferenceGenerate();
            }
        }, [shouldSampleRefGenerate]);

        useEffect(() => {
            formInitialize(FORM_NAME, mode === 'new' ? INITIAL_VALUES : {});

            return () => {
                destroyShapeForm();
            };
        }, [mode]);

        const { complete, proceedText } = useStepperContainer(
            activeStep,
            contents
        );
        const schema = useSchema(mode, formValues, isStyleBottom, isSealed);

        const [handleNext, handlePublish, setupLocked, reset] = useHandles({
            dispatch,
            mode,
            activeStep,
            publish,
            setupLockedFromForm
        });

        const handleHandover = useCallback(
            dispatchProps => {
                return () =>
                    handover({
                        mode,
                        handleComplete: reset,
                        ...dispatchProps
                    });
            },
            [dispatch]
        );

        const handleSave = useCallback(() => saveDraft({ mode }), [
            mode,
            saveDraft
        ]);

        const areValid = useValidate(formValues, schema, formErrors);

        const canProceed = useNext(state, areValid);

        const shouldPreview = useMemo(() => {
            return activeStep === contents.length - 2;
        }, [activeStep, contents.length]);

        const handleNextWithSubmit = useCallback(() => {
            if (!canProceed) {
                handleReduxFormSubmit();
                return;
            }
            handleNext();
        }, [canProceed, activeStep]);

        const shouldFetchData = useMemo(
            () => mode !== 'new' && activeStep === 0,
            [mode, activeStep]
        );

        const shouldCancel = useMemo(() => mode !== 'new', [mode]);

        const shouldPublish = useMemo(
            () => !(mode === 'new' && [0, 1].includes(activeStep)),
            [mode, activeStep]
        );

        const shouldSave = useMemo(() => mode !== 'amend', [mode]);

        useEffect(() => {
            dispatch({ type: 'validate', payload: areValid });
        }, [activeStep, contents.length]);

        const publishHandlerInPreview = useMemo(
            () => props => {
                if (!canProceed) {
                    handleReduxFormSubmit();
                    return;
                }
                handleModal({ ...props, handlePublish, mode });
            },
            [handleModal, canProceed]
        );

        const initialize = useCallback(
            state => ({
                ...state,
                canSave: areValid.saveDraft,
                canPublish: areValid.publish,
                mode,
                reset,
                handleSave,
                handleHandover,
                handlePublish,
                shouldFetchData,
                shouldCancel,
                shouldPublish,
                shouldSave,
                setupLocked,
                shouldPreview,
                handlePreviewModal: publishHandlerInPreview,
                handleReduxFormSubmit,
                sampleRefLoading,
                isSealed
            }),
            [
                areValid.saveDraft,
                areValid.publish,
                mode,
                shouldFetchData,
                shouldCancel,
                shouldPublish,
                shouldSave,
                setupLocked,
                shouldPreview,
                handleHandover,
                sampleRefLoading,
                isSealed
            ]
        );

        return (
            <Paper className={c.container}>
                <ShapeProvider initialize={initialize}>
                    {children}
                </ShapeProvider>
                {!complete &&
                    (!shouldPreview ? (
                        <Button
                            className={c.button}
                            onClick={handleNextWithSubmit}
                            loading={formSubmitting}
                        >
                            {proceedText}
                        </Button>
                    ) : (
                        <Button
                            className={c.button}
                            onClick={publishHandlerInPreview}
                        >
                            Preview
                        </Button>
                    ))}
            </Paper>
        );
    }
);

export default ShapeContainer;
