export const CAD_STATUS = '@status';
export const CAD_AVAILABILITY = '@availability';

export default {
    [CAD_STATUS]: {
        url: 'CadStatus'
    },
    [CAD_AVAILABILITY]: {
        url: 'CadAvailability'
    }
};
