import React from 'react';

// Material
import { withStyles } from '@material-ui/core';

import SelectRetailer from './SelectRetailer';

const styles = ({ spacing }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        width: spacing.unit * 36,
        backgroundColor: 'white'
    },
    container: {
        paddingBottom: 0
    }
});

const RetailerBreakdownSelector = withStyles(styles)(({ classes: c }) => {
    const selectProps = {
        label: '',
        styles: { container: c.container }
    };
    return (
        <div className={c.root}>
            <SelectRetailer {...selectProps} />
        </div>
    );
});

export default RetailerBreakdownSelector;
