import { SET_DATA_GRID_SETTINGS } from './redux.actionTypes';

const initialState = {
    settings: {}
};

export default (
    state = initialState,
    { type, payload, meta: { moduleName } = {} }
) => {
    switch (type) {
        case SET_DATA_GRID_SETTINGS: {
            const existingSettings = state.settings;
            const existingModuleSettings = existingSettings[moduleName] || {};

            return {
                ...state,
                settings: {
                    ...existingSettings,
                    [moduleName]: { ...existingModuleSettings, ...payload }
                }
            };
        }

        default: {
            return state;
        }
    }
};
