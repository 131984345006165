import { put, call } from 'redux-saga/effects';
import { change, initialize } from 'redux-form';
import { apiRequest } from '@libs/apiRequest';
import { splice } from '@libs/object';
import { normalizeAttachments } from 'common/redux.normalizers';
import { normalizeDate } from '@baseComponents/Date';
import { setSnack } from 'utilities/redux.actions';
import { normalizeServerFailedResponse } from 'common/redux.normalizers';

import { FORM_NAME } from '../../Print/common';
import {
    normalizeBoughtDetails,
    normalizeSpecFabrics,
    normalizeFile
} from '../../redux.normalizers';
import { INITIAL_VALUES } from '../../Print/common';

export default function*(sampleRef, isCopy = false, isFabricOption = false) {
    try {
        const {
            data: {
                data: [
                    {
                        fabrics,
                        boughts,
                        attachments,
                        techPackPdfUrl,
                        requestedDate,
                        sampleRequiredDate,
                        numberOfColours,
                        ...data
                    } = {}
                ] = []
            } = {}
        } = yield call(apiRequest, {
            url: 'Sample/GetPrintSampleByRef',
            params: {
                sampleRef
            }
        });

        const nestedNormalizedData = {
            ...data,
            numberOfColours: !!numberOfColours ? numberOfColours : 1,
            fabrics: normalizeSpecFabrics(fabrics, null, false, isCopy),
            boughts: normalizeBoughtDetails(boughts, isCopy),
            attachments: normalizeAttachments(attachments, isCopy),
            requestedDate:
                normalizeDate(requestedDate) || normalizeDate(new Date()),
            sampleRequiredDate: normalizeDate(sampleRequiredDate),
            ...(!!techPackPdfUrl
                ? { techPackPdfUrl: normalizeFile(techPackPdfUrl) }
                : {})
        };

        const copyOnlyAmendOrIncomplete = {
            requestedDate: 'dateRequested',
            sampleRequiredDate: 'dateRequiredBy',
            sampleRef: 'sampleReference',
            id: 'id',
            developmentNo: 'developmentNumber'
        };
        const {
            cad,
            version,
            cadDescription,
            designSource,
            ...values
        } = splice(nestedNormalizedData, {
            // We don't have fabric or copy option in amend/incomplete
            ...(isFabricOption || isCopy ? {} : copyOnlyAmendOrIncomplete),
            cadRef: 'cad',
            cadVersion: 'version',
            graphicDescription: 'cadDescription',
            retailerId: 'retailer',
            genderId: 'gender',
            ageId: 'age',
            categoryId: 'category',
            departmentId: 'department',
            styleId: 'style',
            designerFromId: 'designSource',
            countryId: 'country',
            requestTypeId: 'requestType',
            fitId: 'fitOption',
            ukSizeId: 'sendToEcho',
            buyerSizeId: 'sendToBuyer',
            departmentContactPerson: 'buyer',
            licensorSizeId: 'sendToLicensor',
            isSampleRequired: 'photoApproval',
            styleDescription: 'styleDescription',
            specRef: 'specReference',
            garmentDescription: 'specDescription',
            specComments: 'additionalComments',
            artworkLinks: 'artworkLinks',
            numberOfColours: 'numberOfColours',
            techPackPdfUrl: 'techPack',
            fabrics: 'fabrics',
            boughts: 'boughtDetails',
            attachments: 'attachments',
            cadImageForBuyer: 'cadImageForBuyer',
            hasOrdered: 'hasOrdered'
        });

        yield put(
            initialize(FORM_NAME, { ...INITIAL_VALUES, ...values }, isCopy, {
                updateUnregisteredFields: true,
                keepDirty: isCopy
            })
        );

        yield put(change(FORM_NAME, 'cad', cad));
        yield new Promise(res => setTimeout(() => res(), 0));
        yield put(change(FORM_NAME, 'version', version));
        yield put(change(FORM_NAME, 'cadDescription', cadDescription));
        yield put(change(FORM_NAME, 'designSource', designSource));
    } catch (error) {
        const serverMessage = yield call(normalizeServerFailedResponse, error);
        yield put(
            setSnack({
                message: `Failed to fetch data ${serverMessage}`,
                type: 'error',
                duration: 8000
            })
        );
    }
}
