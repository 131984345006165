import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';

import { DOMAIN_NAME } from './common';

const dataGridSelector = state => state[DOMAIN_NAME];

export const dataGridSettingsSelector = createSelector(
    dataGridSelector,
    dataGrid => dataGrid.settings || {}
);

export const dataGridSettingsSelectorByModule = createCachedSelector(
    state => dataGridSettingsSelector(state),
    (state, moduleName) => moduleName,
    (settings, moduleName) => settings[moduleName] || {}
)((state, moduleName) => `dataGridSettings-${moduleName}`);

export const dataGridDatasetStateSelector = slicerNames => {
    const slicerSelectors = slicerNames.map(slicerName => state =>
        state[slicerName]
    );

    return createSelector(
        slicerSelectors,
        (...slicerValues) => {
            return {
                ...slicerValues.reduce((acc, value, index) => {
                    acc[slicerNames[index]] = value;
                    return acc;
                }, {})
            };
        }
    );
};
