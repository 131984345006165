import React from 'react';

import {
    RETAILERS,
    DEPARTMENTS,
    CURRIER,
    SAMPLE_FACTORY,
    DISPATCH_TYPE,
    DISPATCH_SIZE,
    COUNTRIES
} from 'sampling/redux.datasets';
import { DOMAIN_NAME as SAMPLING_DOMAIN_NAME } from 'sampling/common';

import ReferenceLinkModal from './components/ReferenceLinkModal';

export const sampleDispatchDatasets = [
    RETAILERS,
    DEPARTMENTS,
    CURRIER,
    SAMPLE_FACTORY,
    DISPATCH_TYPE,
    DISPATCH_SIZE,
    COUNTRIES
];

export const getColumns = () => {
    return [
        {
            name: 'id',
            header: 'Id',
            visible: false,
            type: 'number'
        },
        {
            name: 'dispatchRef',
            header: 'Dispatch Ref',
            filterable: true,
            type: 'text',
            render: ({ column, row }) => (
                <ReferenceLinkModal dispatchRef={row[column.name]} />
            )
        },
        {
            name: 'currierId',
            header: 'Currier',
            filterable: true,
            type: 'select',
            domain: SAMPLING_DOMAIN_NAME,
            entity: CURRIER
        },
        {
            name: 'trackingRef',
            header: 'Tracking Ref',
            type: 'text',
            filterable: true
        },
        {
            name: 'retailerId',
            header: 'Retailer',
            filterable: true,
            type: 'select',
            domain: SAMPLING_DOMAIN_NAME,
            entity: RETAILERS
        },
        {
            name: 'gender',
            header: 'Gender',
            filterable: true,
            type: 'text'
        },
        {
            name: 'department',
            header: 'Department',
            filterable: true,
            type: 'text'
        },
        {
            name: 'notifyFao',
            header: 'FAO',
            type: 'text'
        },
        {
            name: 'dispatchDate',
            header: 'Actual Send Date',
            filterable: true,
            type: 'date'
        },
        {
            name: 'eta',
            header: 'ETA',
            filterable: true,
            type: 'date'
        },
        {
            name: 'actRcvdDt',
            header: 'Actual Received Date',
            editable: true,
            type: 'date'
        },
        {
            name: 'dispatchTypeId',
            header: 'Type',
            filterable: true,
            type: 'select',
            domain: SAMPLING_DOMAIN_NAME,
            entity: DISPATCH_TYPE
        },
        {
            name: 'dispatchSizeId',
            header: 'Size',
            filterable: true,
            type: 'select',
            domain: SAMPLING_DOMAIN_NAME,
            entity: DISPATCH_SIZE
        },
        {
            name: 'sampleRef',
            header: 'Sample Ref',
            type: 'text',
            filterable: true
        },
        {
            name: 'sampleDevNo',
            header: 'Sample Version',
            type: 'text'
        },
        {
            name: 'fabricType',
            header: 'Fabric Type',
            type: 'text'
        },
        {
            name: 'fabricComposition',
            header: 'Composition',
            type: 'text',
            defaultWidth: 250,
            minWidth: 150,
            maxWidth: 800
        },
        {
            name: 'fabricGsm',
            header: 'GSM',
            type: 'number'
        },
        {
            name: 'colour',
            header: 'Colour',
            type: 'text',
            filterable: true
        },
        {
            name: 'dispatchQuantity',
            header: 'Units',
            type: 'number',
            filterable: true
        },
        {
            name: 'pos',
            header: 'POs',
            type: 'text',
            defaultWidth: 250,
            minWidth: 150,
            maxWidth: 800
        },
        {
            name: 'originCountryId',
            header: 'Source Country',
            type: 'select',
            filterable: true,
            domain: SAMPLING_DOMAIN_NAME,
            entity: COUNTRIES
        },
        {
            name: 'destinationCountryId',
            header: 'Destination Country',
            type: 'select',
            filterable: true,
            domain: SAMPLING_DOMAIN_NAME,
            entity: COUNTRIES
        },
        {
            name: 'factoryId',
            header: 'Factory',
            type: 'select',
            filterable: true,
            domain: SAMPLING_DOMAIN_NAME,
            entity: SAMPLE_FACTORY
        },
        {
            name: 'season',
            header: 'Season',
            type: 'text'
        },
        {
            name: 'designer',
            header: 'Designer',
            type: 'text'
        },
        {
            name: 'fabricGroup',
            header: 'Fabric Group',
            type: 'text'
        },
        {
            name: 'remarks',
            header: 'Remarks',
            type: 'text',
            defaultWidth: 250,
            minWidth: 150,
            maxWidth: 800
        }
    ];
};
