import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import PDFIcon from '@assets/Icons/Pdf';
import { valueSelector } from '@libs/datasets';
import { setSnack } from 'utilities/redux.actions';

import { getDataForSelectedRowsWithSamplesSelector } from 'sampling/redux.selectors';
import {
    DEPARTMENTS,
    SAMPLE_FACTORY,
    SEASONS,
    CURRIER,
    DESIGN_SOURCES,
    SAMPLE_ACTIONS,
    SAMPLE_STATUSES,
    SAMPLE_ORDER_STATUS
} from 'sampling/redux.datasets';
import { DOMAIN_NAME as SAMPLING_DOMAIN_NAME } from 'sampling/common';
import { ALL_DESIGNERS } from 'cadUpload/redux.datasets';
import { DOMAIN_NAME as CAD_DOMAIN_NAME } from 'cadUpload/common';

import ExportTitleAndIcon from '../ExportTitleAndIcon';
import { useSamplePDF } from './hooks';

const prepareData = createSelector(
    state => state,
    (_, selectedData) => selectedData,
    (state, data) => {
        return data.map(row => ({
            ...row,
            department: valueSelector(
                state,
                SAMPLING_DOMAIN_NAME,
                DEPARTMENTS,
                row.departmentId
            ),
            designer: valueSelector(
                state,
                CAD_DOMAIN_NAME,
                ALL_DESIGNERS,
                row.designerId
            ),
            sampleOrderStatus: valueSelector(
                state,
                SAMPLING_DOMAIN_NAME,
                SAMPLE_ORDER_STATUS,
                row.orderStatus
            ),
            sampleStatus: valueSelector(
                state,
                SAMPLING_DOMAIN_NAME,
                SAMPLE_STATUSES,
                row.sampleStatusId
            ),
            sampleAction: valueSelector(
                state,
                SAMPLING_DOMAIN_NAME,
                SAMPLE_ACTIONS,
                row.sampleActionId
            ),
            factory: valueSelector(
                state,
                SAMPLING_DOMAIN_NAME,
                SAMPLE_FACTORY,
                row.factoryId
            ),
            season: valueSelector(
                state,
                SAMPLING_DOMAIN_NAME,
                SEASONS,
                row.seasonId
            ),
            currier: valueSelector(
                state,
                SAMPLING_DOMAIN_NAME,
                CURRIER,
                row.currierId
            ),
            designSource: valueSelector(
                state,
                SAMPLING_DOMAIN_NAME,
                DESIGN_SOURCES,
                row.designFromId
            )
        }));
    }
);

const mapStateToProps = (state, { data, selectedRows }) => {
    const {
        sampleType,
        hasBoth,
        selectedSamples
    } = getDataForSelectedRowsWithSamplesSelector(data, selectedRows);
    const preparedData = prepareData(state, selectedSamples);
    return { sampleType, hasBoth, data: preparedData };
};

const mapDispatchToProps = dispatch => ({
    setSnack: (...args) => dispatch(setSnack.apply(null, args))
});

const PDF = connect(
    mapStateToProps,
    mapDispatchToProps
)(({ data, sampleType, hasBoth, setSnack }) => {
    const [handleExport, exportLoading] = useSamplePDF({
        data,
        sampleType,
        setSnack,
        hasBoth
    });
    return (
        <ExportTitleAndIcon
            Icon={PDFIcon}
            title='PDF'
            onClick={handleExport}
            disabled={exportLoading}
        />
    );
});

export default PDF;
