import { MAIN_BODY_FABRIC_ID } from './common';
import { normalizeDate } from '../@baseComponents/Date';
import { deserializeFilename } from '../@libs/filenames';
import { renameProperties } from '../@libs/object';

const normalizeRetailers = ({ data: { data = [] } = {} } = {}) => {
    return Object.values(
        data.reduce((acm, { retailerId, retailer, countryId, slNo }) => {
            if (acm.hasOwnProperty(retailerId)) {
                const { countries = [], ...retailerEntry } = acm[retailerId];
                return {
                    ...acm,
                    [retailerId]: {
                        ...retailerEntry,
                        countries: [...countries, countryId]
                    }
                };
            } else
                return {
                    ...acm,
                    [retailerId]: {
                        id: retailerId,
                        retailer,
                        countries: [countryId],
                        order: slNo
                    }
                };
        }, {})
    )
        .sort((a, b) => {
            if (a.order < b.order) {
                return -1;
            }
            if (a.order > b.order) {
                return 1;
            }
            return 0;
        })
        .map(({ order, ...rest }) => rest);
};

// When fetching from GetFabricBySample
const validateNullable = stringObject => {
    const [[key, value]] = Object.entries(stringObject);
    return value ? { [key]: value } : {};
};

const validateFabricFor = (fabricFor, mainBodyExists) =>
    fabricFor === MAIN_BODY_FABRIC_ID && mainBodyExists ? null : fabricFor;

const normalizeSpecFabrics = (
    fabrics,
    reference = null,
    mainBodyExists = false,
    forceNew = false
) =>
    fabrics.map(
        ({
            id,
            fabricForId,
            fabricTypeId,
            compositionId,
            gsmId,
            dyeTypeId,
            specialFinishId,
            specialFabricRef,
            fabricColour,
            eld,
            isDischargeable,
            isEngineeredStripe,
            isYds,
            istc,
            tcText,
            fabFrom
        }) => ({
            id: forceNew || !id ? 0 : id,
            fabricFor: validateFabricFor(Number(fabricForId), mainBodyExists),
            fabricType: Number(fabricTypeId),
            composition: Number(compositionId),
            finishedGsm: Number(gsmId),
            dyeType: dyeTypeId ? Number(dyeTypeId) : null,
            specialFinish: specialFinishId ? Number(specialFinishId) : null,
            ...validateNullable({ specialFabricRef }),
            ...validateNullable({ fabricColour }),
            ...validateNullable({ eld }),
            ...validateNullable({ isDischargeable }),
            ...validateNullable({ isEngineeredStripe }),
            ...validateNullable({ isYds }),
            ...validateNullable({ isTc: istc }),
            ...validateNullable({ tcText }),
            ...validateNullable({ fabFrom: fabFrom || reference })
        })
    );

const normalizeBoughtDetails = (boughtDetails, forceNew = false) =>
    boughtDetails.map(
        ({
            id,
            frontImage,
            backImage,
            boughtById,
            boughtDate,
            boughtLocationId,
            boughtRef,
            tripReference
        }) => ({
            id: forceNew || !id ? 0 : id,
            images: [frontImage, backImage].filter(image => !!image),
            boughtSampleRef: boughtRef,
            ...validateNullable({ boughtBy: Number(boughtById) }),
            ...validateNullable({ boughtLocation: Number(boughtLocationId) }),
            tripReference,
            boughtDate: normalizeDate(boughtDate)
        })
    );

const normalizeTrimDetails = (trimDetails, forceNew = false) =>
    trimDetails.map(
        ({
            id,
            trimCardRef,
            typeId,
            supplierId,
            description,
            size,
            composition,
            application,
            position,
            supplierRef,
            colour,
            quantity,
            originalImageUrl
        }) => ({
            id: forceNew || !id ? 0 : id,
            trimCardReference: trimCardRef,
            image: originalImageUrl,
            ...validateNullable({ trimType: Number(typeId) }),
            ...validateNullable({ trimSupplier: Number(supplierId) }),
            ...validateNullable({ description }),
            ...validateNullable({ size }),
            ...validateNullable({ composition }),
            ...validateNullable({ application }),
            ...validateNullable({ position }),
            ...validateNullable({ supplierRef }),
            ...validateNullable({ colour }),
            ...validateNullable({ quantity: Number(quantity) })
        })
    );

const normalizeFile = url => {
    if (url)
        return {
            url,
            filename: deserializeFilename(url)
        };
};

const normalizeImagePayload = payload => {
    if (payload)
        return renameProperties(payload, {
            sizeOriginal: 'originalImageSizeUrl',
            size100: 'image100SizeUrl',
            size350: 'image350SizeUrl',
            size800: 'image800SizeUrl'
        });
};

const normalizeSampleSizes = ({ data: { data = [] } = {} } = {}) =>
    data.reduce(
        (acm, { sampleSizeId, sampleSize, departmentId, isDefault }) => {
            return [
                ...acm,
                {
                    id: sampleSizeId,
                    sampleSize,
                    departmentId,
                    isDefault
                }
            ];
        },
        []
    );
const normalizeEmailGroup = ({
    data: { data = [], nextPage = false } = {}
} = {}) => {
    const normalizedData = data.reduce(
        (acm, { emailGroupName, emailGroup }) => {
            const emails = emailGroup.split(',');
            const spaceAfter4Items = emails.map((email, idx) => {
                if (idx !== 0 && idx % 4 === 0) return `${email}, `;
                return `${email.trim()},`;
            });

            return [
                ...acm,
                {
                    'Group Name': emailGroupName,
                    Emails: spaceAfter4Items
                }
            ];
        },
        []
    );
    return { data: normalizedData, nextPage };
};

const normalizeSamplingReportData = data => {
    const breakdownKeys = [
        { name: 'sampleType', label: 'Sample Type' },
        { name: 'designFrom', label: 'Design From' },
        { name: 'gender', label: 'Gender' },
        { name: 'category', label: 'Category' },
        { name: 'age', label: 'Age' },
        { name: 'country', label: 'Country' },
        { name: 'orderStatus', label: 'Order Status' }
    ];

    const output = {
        overall: [],
        overallDetailsBreakdown: {}
    };

    const retailerMap = {};

    data.forEach(item => {
        // Aggregating the overall data
        let overallItem = output.overall.find(i => i.name === item.retailer);
        if (!overallItem) {
            overallItem = {
                name: item.retailer,
                value: 0,
                id: Number(item.retailerId)
            };
            output.overall.push(overallItem);
        }
        overallItem.value += 1;

        // Preparing retailer-specific breakdown
        if (!retailerMap[item.retailerId]) {
            retailerMap[item.retailerId] = {};
            breakdownKeys.forEach(({ label }) => {
                retailerMap[item.retailerId][label] = [];
            });
        }

        breakdownKeys.forEach(({ name, label }) => {
            const keyValue = item[name] || 'N/A';
            let breakdownItem = retailerMap[item.retailerId][label].find(
                i => i.name === keyValue
            );
            if (!breakdownItem) {
                breakdownItem = { name: keyValue, value: 0 };
                retailerMap[item.retailerId][label].push(breakdownItem);
            }
            breakdownItem.value += 1;

            // Aggregating overall details breakdown
            if (!output.overallDetailsBreakdown[label]) {
                output.overallDetailsBreakdown[label] = [];
            }
            let overallBreakdownItem = output.overallDetailsBreakdown[
                label
            ].find(i => i.name === keyValue);
            if (!overallBreakdownItem) {
                overallBreakdownItem = { name: keyValue, value: 0 };
                output.overallDetailsBreakdown[label].push(
                    overallBreakdownItem
                );
            }
            overallBreakdownItem.value += 1;
        });
    });

    // Sorting the overall and breakdown data by value in descending order
    output.overall.sort((a, b) => b.value - a.value);

    breakdownKeys.forEach(({ label }) => {
        if (output.overallDetailsBreakdown[label]) {
            output.overallDetailsBreakdown[label].sort(
                (a, b) => b.value - a.value
            );
        }
        Object.values(retailerMap).forEach(retailer => {
            if (retailer[label]) {
                retailer[label].sort((a, b) => b.value - a.value);
            }
        });
    });

    Object.assign(output, retailerMap);

    return output;
};

export {
    normalizeRetailers,
    normalizeSpecFabrics,
    normalizeBoughtDetails,
    normalizeTrimDetails,
    normalizeFile,
    normalizeImagePayload,
    normalizeSampleSizes,
    normalizeEmailGroup,
    normalizeSamplingReportData
};
