import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import createCachedSelector from 're-reselect';

import { valueSelector, entitySelector } from '@libs/datasets';

import { DOMAIN_NAME, FORM_NAME } from './common';
import {
    ARTWORK_SOURCES,
    DESIGNERS,
    LICENSORS,
    PROPERTIES,
    SLEEVE_TYPES,
    STYLES,
    TYPES,
    ALL_DESIGNERS
} from './redux.datasets';
import { userIdSelector } from '../auth/redux.selectors';
import {
    sizePathsSelector,
    developmentNameSelector
} from 'common/redux.selectors';
import { BUYERID } from './common';

const cadUploadSelector = state => state[DOMAIN_NAME];
const cadUploadFormSelector = formValueSelector(FORM_NAME);

const isLicensedSelector = createSelector(
    state => cadUploadFormSelector(state, 'type'),
    id => id === 2 // Licensed Type Id
);

const typeSelector = createSelector(
    cadUploadSelector,
    state => cadUploadFormSelector(state, 'type'),
    (cadUpload, typeId) => {
        const { type = '' } =
            cadUpload[TYPES].find(({ id }) => id === typeId) || {};
        return type;
    }
);

const isShapedSelector = createSelector(
    state => cadUploadFormSelector(state, 'type'),
    id => id === 3 // Shape Type Id
);

const developmentTypeSelector = state =>
    cadUploadFormSelector(state, 'developmentType');

const versionsSelector = state =>
    cadUploadFormSelector(state, 'versions') || [];
const cadReferenceSelector = state =>
    cadUploadFormSelector(state, 'cadReference');
const descriptionSelector = state =>
    cadUploadFormSelector(state, 'description');

const cadSizePathsSelector = state => sizePathsSelector(state, 'cad');

const reportPropsSelector = createSelector(
    state =>
        cadUploadFormSelector(
            state,
            'cadReference',
            'description',
            'licensor',
            'property',
            'artworkSource',
            'designer',
            'type',
            'developmentType',
            'gender',
            'style',
            'sleeveType',
            'versions'
        ),
    cadSizePathsSelector,
    state => state,
    (
        {
            cadReference,
            description,
            type,
            licensor,
            property,
            designer,
            gender,
            style,
            sleeveType,
            artworkSource,
            developmentType,
            versions
        },
        sizePaths,
        state
    ) => {
        const selectorValues = {
            type: valueSelector(state, DOMAIN_NAME, TYPES, type),
            licensor: valueSelector(state, DOMAIN_NAME, LICENSORS, licensor),
            property: valueSelector(state, DOMAIN_NAME, PROPERTIES, property),
            designer: valueSelector(state, DOMAIN_NAME, DESIGNERS, designer),
            style: valueSelector(state, DOMAIN_NAME, STYLES, style),
            sleeveType: valueSelector(
                state,
                DOMAIN_NAME,
                SLEEVE_TYPES,
                sleeveType
            ),
            artworkSource: valueSelector(
                state,
                DOMAIN_NAME,
                ARTWORK_SOURCES,
                artworkSource
            ),
            developmentVersion: developmentNameSelector(
                state,
                'CAD',
                developmentType
            )
        };

        const values = Object.entries(selectorValues).reduce(
            (acm, [key, value]) => (!!value ? { ...acm, [key]: value } : acm),
            {}
        );

        const preview = versions[0];

        return {
            cadReference,
            description,
            preview,
            versions,
            ...values
        };
    }
);

const generateCadPayloadSelector = createSelector(
    userIdSelector,
    state => cadUploadFormSelector(state, 'type', 'designer', 'licensor'),
    (userId, formValues) => ({ userId, ...formValues })
);

const cadTypesSelector = createSelector(
    state => entitySelector(state, DOMAIN_NAME, TYPES),
    types =>
        types
            .filter(({ isActive }) => isActive)
            .map(({ id, type }) => ({ value: id, label: type }))
);

const licensorsSelector = createSelector(
    state => entitySelector(state, DOMAIN_NAME, LICENSORS),
    licensors =>
        licensors
            .filter(({ isActive }) => isActive)
            .map(({ id, licensor }) => ({ value: id, label: licensor }))
);

const propertiesSelector = createCachedSelector(
    state => entitySelector(state, DOMAIN_NAME, PROPERTIES),
    (state, licensor) => licensor,
    (properties, licensor) => {
        const filteredProperties = properties
            .filter(
                ({ licensorId, isActive }) =>
                    Number(licensorId) === licensor && isActive
            )
            .map(({ id, property }) => ({ label: property, value: id }));
        return filteredProperties;
    }
)((state, licensor) => `properties-${licensor}`);

const artworkSourceSelector = createCachedSelector(
    state => entitySelector(state, DOMAIN_NAME, ARTWORK_SOURCES),
    (state, type) => type,
    (artworkSources, type) =>
        artworkSources
            .filter(value => {
                if (type !== 2) {
                    if (value.id !== 2) {
                        return value;
                    }
                } else {
                    return value;
                }
            })
            .map(({ id, artworkSource }) => ({
                label: artworkSource,
                value: id
            }))
)((state, type) => `type-${type}`);

const stylesAlphabeticallySelector = createCachedSelector(
    state => entitySelector(state, DOMAIN_NAME, STYLES),
    styles =>
        styles
            .sort((a, b) => {
                if (a.style < b.style) return -1;
                if (a.style > b.style) return 1;
                return 0;
            })
            .map(({ id, style }) => ({
                label: style,
                value: id
            }))
)(state => `styles-alphabetically`);

const getDesignerByLoggedinUser = createCachedSelector(
    state => entitySelector(state, DOMAIN_NAME, ALL_DESIGNERS),
    (state, userId) => userId,
    (designers, loggedinUserId) => {
        const designer =
            designers.find(({ userId }) => userId === loggedinUserId) || {};
        return designer.id ? designer.id : '';
    }
)((state, userId) => `get-designer-by-loggedin-user-${userId}`);

export {
    cadUploadFormSelector,
    isLicensedSelector,
    typeSelector,
    developmentTypeSelector,
    versionsSelector,
    cadReferenceSelector,
    descriptionSelector,
    cadSizePathsSelector,
    reportPropsSelector,
    generateCadPayloadSelector,
    cadTypesSelector,
    licensorsSelector,
    propertiesSelector,
    artworkSourceSelector,
    isShapedSelector,
    stylesAlphabeticallySelector,
    getDesignerByLoggedinUser
};
