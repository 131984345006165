import { useCallback, useEffect, useMemo, useState } from 'react';

import {
    dataGridDefaultRootProps,
    boolColumnDefaults,
    textColumnDefaults,
    numberColumnDefaults,
    selectColumnDefaults,
    dateColumnDefaults,
    componentColumnDefaults,
    imageColumnDefaults,
    columnDefaults
} from '../dataGridDefaultValues';

import useSort from './useSort';
import useColumnFreeze from './useColumnFreeze';
import useColumnVisibility from './useColumnVisibility';
import useRowSelection from './useRowSelection';
import useColumnReorder from './useColumnReorder';
import useFilter from './useFilter';
import usePagination from './usePagination';

// Hook for combining all logic

const useDataGrid = props => {
    const {
        columns: rawColumns,
        data,
        fetchGridData,
        storedDataGridSettings,
        setDataGridSettings,
        paginationProps: serverPaginationProps,
        ...restProps
    } = {
        ...dataGridDefaultRootProps,
        ...props
    };

    const [gridData, setGridData] = useState(data);

    // Defautls value assign to column according to the column type
    const columns = useMemo(() => {
        const getColumnDefaults = type => {
            switch (type) {
                case 'text':
                    return { ...textColumnDefaults };
                case 'number':
                    return { ...numberColumnDefaults };
                case 'select':
                    return { ...selectColumnDefaults };
                case 'date':
                    return { ...dateColumnDefaults };
                case 'boolean':
                    return { ...boolColumnDefaults };
                case 'image':
                    return { ...imageColumnDefaults };
                default:
                    return { ...columnDefaults };
            }
        };

        return rawColumns.map(column => ({
            ...getColumnDefaults(column.type),
            ...(column.filterable || column.editable
                ? componentColumnDefaults
                : {}),
            ...column
        }));
    }, [rawColumns]);

    // Create a map of column names to column objects for quick access
    const columnMap = useMemo(() => {
        return columns.reduce((map, column) => {
            map[column.name] = column;
            return map;
        }, {});
    }, [columns]);

    const {
        sortedColumn,
        sortDirection,
        sortColumn,
        ...sortingProps
    } = useSort();

    const {
        visibleColumns,
        clearColumnVisibilitySettings,
        ...columnVisibility
    } = useColumnVisibility(
        columns,
        storedDataGridSettings,
        setDataGridSettings
    );

    const {
        clearColumnFreezeSettings,
        frozenColumns,
        ...columnFreeze
    } = useColumnFreeze(columns, storedDataGridSettings, setDataGridSettings);

    const {
        orderedColumns,
        clearColumnReorderSettings,
        ...columnReorder
    } = useColumnReorder(columns, storedDataGridSettings, setDataGridSettings);

    const { filterValues, removeAllFilters, ...filtersProps } = useFilter();

    const { currentPage, pageSize, ...restPaginationProps } = usePagination(
        serverPaginationProps,
        filterValues
    );

    const handleClearAllSettings = useCallback(() => {
        clearColumnVisibilitySettings();
        clearColumnFreezeSettings();
        clearColumnReorderSettings();
    }, [
        clearColumnFreezeSettings,
        clearColumnVisibilitySettings,
        clearColumnReorderSettings
    ]);

    const updateRowsInGrid = useCallback(updatedRows => {
        setGridData(prevGridData =>
            prevGridData.map(mainItem => {
                const updatedItem = updatedRows.find(
                    updatedItem => updatedItem.id === mainItem.id
                );
                if (updatedItem) {
                    // If there's a matching updated item, replace it in the result
                    return updatedItem;
                }
                return mainItem;
            })
        );
    }, []);

    const sortedData = useMemo(() => {
        if (!sortedColumn || gridData.length === 0) {
            return gridData;
        }

        const columnType = typeof gridData[0][sortedColumn];

        const compareValues = (a, b) => {
            if (a === b) return 0;
            if (a === null || a === undefined)
                return sortDirection === 'asc' ? -1 : 1;
            if (b === null || b === undefined)
                return sortDirection === 'asc' ? 1 : -1;

            if (columnType === 'number') {
                return (a - b) * (sortDirection === 'asc' ? 1 : -1);
            } else {
                return (
                    a.toString().localeCompare(b.toString()) *
                    (sortDirection === 'asc' ? 1 : -1)
                );
            }
        };

        return [...gridData].sort((a, b) =>
            compareValues(a[sortedColumn], b[sortedColumn])
        );
    }, [gridData, sortedColumn, sortDirection]);

    const gridColumns = useMemo(() => {
        return orderedColumns
            .filter(columnName => visibleColumns.includes(columnName))
            .map(columnName => columnMap[columnName]);
    }, [columnMap, orderedColumns, visibleColumns]);

    const gridFrozenColumns = useMemo(() => {
        return frozenColumns
            .filter(columnName => visibleColumns.includes(columnName))
            .map(columnName => columnMap[columnName]);
    }, [columnMap, frozenColumns, visibleColumns]);

    const prepareQueryParams = useMemo(() => {
        // Convert the filter values to the desired payload structure
        const filters = Object.entries(filterValues).map(([name, filter]) => ({
            name,
            ...filter
        }));
        return {
            filters: !!filters.length ? JSON.stringify(filters) : '',
            pageNumber: currentPage,
            pageSize
        };
    }, [JSON.stringify(filterValues), currentPage, pageSize]);

    const refetchData = useCallback(() => {
        fetchGridData(prepareQueryParams);
    }, [prepareQueryParams, fetchGridData]);

    useEffect(() => {
        let isSubscribed = true;
        isSubscribed && setGridData(data);
        return () => {
            isSubscribed = false;
        };
    }, [data]);

    useEffect(() => {
        let isSubscribed = true;
        isSubscribed && fetchGridData(prepareQueryParams);
        return () => {
            isSubscribed = false;
        };
    }, [prepareQueryParams, fetchGridData]);

    const paginationProps = useMemo(
        () => ({
            currentPage,
            pageSize,
            ...restPaginationProps
        }),
        [currentPage, pageSize, restPaginationProps]
    );

    return {
        sortedColumn,
        sortDirection,
        sortColumn,
        sortedData,
        visibleColumns,
        orderedColumns,
        gridColumns,
        columns,
        columnMap,
        clearColumnVisibilitySettings,
        clearColumnFreezeSettings,
        clearColumnReorderSettings,
        handleClearAllSettings,
        filterValues,
        removeAllFilters,
        paginationProps,
        refetchData,
        gridFrozenColumns,
        frozenColumns,
        updateRowsInGrid,
        ...filtersProps,
        ...sortingProps,
        ...columnVisibility,
        ...columnReorder,
        ...columnFreeze,
        ...useRowSelection(gridData),
        ...restProps
    };
};

export default useDataGrid;
