import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import useGridRowMutation from '@libHooks/useGridRowMutation';
import {
    datasetSelector,
    datasetLoadingSelector,
    valueSelector,
    fetchDatasets,
    createFetchGridDataset,
    gridDatasetSelector
} from '@libs/datasets';
import { userIdSelector } from 'auth/redux.selectors';
import { setSnack } from 'utilities/redux.actions';
import palette from 'app/Theme/palette';

import DataGrid from 'dataGrid';
import { dataGridSettingsSelectorByModule } from 'dataGrid/redux.selectors';
import { setDataGridSettings } from 'dataGrid/redux.actions';

import mutation from './mutation';
import { CP_GRID_DATA } from 'orders/redux.datasets';
import { DOMAIN_NAME } from 'orders/common';
import { MODULE_NAME } from './common';
import { DATASETS, getColumns, PAGE_SIZES } from './columns';
import { ExportOptions, OrderCPUpdateConfirmation } from './components';

const mapState = state => ({
    storedDataGridSettings: dataGridSettingsSelectorByModule(
        state,
        MODULE_NAME
    ),
    datasetSelector: (domain, entity) => datasetSelector(state, domain, entity),
    datasetLoadingSelector: (domain, entity) =>
        datasetLoadingSelector(state, domain, entity),
    valueSelector: (domain, entity, value) =>
        valueSelector(state, domain, entity, value),
    dataGridLoading: datasetLoadingSelector(state, DOMAIN_NAME, CP_GRID_DATA),
    userId: userIdSelector(state),
    pageSizes: PAGE_SIZES,
    ...gridDatasetSelector(state, DOMAIN_NAME, CP_GRID_DATA)
});

const mapDispatchToProps = dispatch => ({
    fetchGridData: payload =>
        dispatch(
            createFetchGridDataset({
                endpoint: 'OrderMains/GetOrdersCpListViewData',
                domain: DOMAIN_NAME,
                entity: CP_GRID_DATA
            })(payload)
        ),
    fetchDatasets: () => dispatch(fetchDatasets(DATASETS)),
    setDataGridSettings: payload =>
        dispatch(setDataGridSettings(MODULE_NAME)(payload)),
    setSnack: (...args) => dispatch(setSnack.apply(null, args))
});

const OrderCP = connect(
    mapState,
    mapDispatchToProps
)(({ fetchDatasets, valueSelector, userId, setSnack, ...rest }) => {
    const [promiseArguments, setPromiseArguments] = useState(null);
    const [rowUpdating, setRowUpdating] = useState(false);
    const mutateRow = useGridRowMutation({
        endpoint: 'OrderMains/OrderCpBulkUpdate',
        userId,
        promiseArguments,
        setPromiseArguments,
        setSnack
    });
    const columns = useMemo(() => getColumns(), []);
    const editableColumns = useMemo(
        () => columns.filter(column => column.editable),
        [columns]
    );
    const computeMutation = useMemo(
        () => mutation(editableColumns, valueSelector),
        [editableColumns, valueSelector]
    );
    const syncGridDataWithServerAsync = useCallback(
        (newRow, oldRow) =>
            new Promise((resolve, reject) => {
                const { mutationText, ...rest } = computeMutation(
                    newRow,
                    oldRow
                );
                if (mutationText) {
                    // Save the arguments to resolve or reject the promise later
                    setPromiseArguments({
                        resolve,
                        reject,
                        newRow,
                        oldRow,
                        ...rest
                    });
                } else {
                    resolve(oldRow); // Nothing was changed
                }
            }),
        [computeMutation]
    );
    const handleNo = useCallback(() => {
        const { oldRow, resolve } = promiseArguments;
        resolve(oldRow); // Resolve with the old row to not update the internal state
        setPromiseArguments(null);
    }, [promiseArguments]);

    const handleYes = useCallback(
        async selectedIds => {
            setRowUpdating(true);
            await mutateRow(selectedIds);
            setRowUpdating(false);
        },
        [mutateRow]
    );

    const rowStyle = useCallback(row => {
        const customRowColor = (() => {
            switch (row.orderStatus) {
                case 'CANCELLED':
                    return palette.error.main;
                case 'HELD':
                    return palette.warning.light;
                default:
                    return null;
            }
        })();

        return customRowColor;
    }, []);

    const isRowEditable = useCallback(row => {
        switch (row.orderStatus) {
            case 'CANCELLED':
            case 'HELD':
                return false;

            default:
                return true;
        }
    }, []);

    useEffect(() => {
        fetchDatasets();
    }, []);

    const renderConfirmDialog = () => {
        if (!promiseArguments) {
            return null;
        }
        const { newRow, oldRow } = promiseArguments;
        const { mutationText, id } = computeMutation(newRow, oldRow);
        return (
            <OrderCPUpdateConfirmation
                open
                onClose={handleNo}
                onConfirm={handleYes}
                mutationText={mutationText}
                loading={rowUpdating}
                updatingRowId={id}
            />
        );
    };

    return (
        <>
            {renderConfirmDialog()}
            <DataGrid
                columns={columns}
                syncGridDataWithServerAsync={syncGridDataWithServerAsync}
                RenderDataGridExportOptions={ExportOptions}
                valueSelector={valueSelector}
                rowStyle={rowStyle}
                isRowEditable={isRowEditable}
                {...rest}
            />
        </>
    );
});

export default OrderCP;
