import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';

// Material
import { withStyles } from '@material-ui/core';

import Button from '@libComponents/Button';

const propTypes = {
    IconComponent: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    iconPosition: PropTypes.oneOf(['left', 'right'])
};

const defaultProps = {
    variant: 'outlined',
    iconPosition: 'left'
};

const styles = ({ spacing }) => ({
    root: {
        width: 'auto'
    },
    leftIcon: {
        marginRight: spacing.unit
    },
    rightIcon: {
        marginLeft: spacing.unit
    }
});

const IconLabelButton = withStyles(styles)(
    ({
        classes: c,
        IconComponent,
        iconPosition,
        label,
        className,
        ...props
    }) => (
        <Button className={clsx(c.root, className)} {...props}>
            {iconPosition === 'right' && label}
            <IconComponent
                className={iconPosition === 'left' ? c.leftIcon : c.rightIcon}
            />
            {iconPosition === 'left' && label}
        </Button>
    )
);

IconLabelButton.propTypes = propTypes;
IconLabelButton.defaultProps = defaultProps;

export { IconLabelButton as default, IconLabelButton };
