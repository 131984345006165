import { normalizeDate } from '@baseComponents/Date/normalizeDate';

export const FORM_NAME = 'SamplingReport';
export const INITIAL_VALUES = {
    startDate: normalizeDate(
        new Date(new Date().setDate(new Date().getDate() - 7))
    ),
    endDate: normalizeDate(new Date())
};
export const SAMPLING_REPORT_ROUTE_ID = 634;
