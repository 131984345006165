import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

import { withStyles, Paper, Typography, Grid } from '@material-ui/core';

import { setSnack } from 'utilities/redux.actions';

import { Button } from '@libComponents/Button';

import {
    descriptionSelector,
    cadReferenceSelector,
    reportPropsSelector,
    cadSizePathsSelector
} from '../../redux.selectors';
import {
    routeToNew,
    routeToAmend,
    routeToNewSample
} from '../../redux.actions';
import { VersionsViewer } from '../../components';
import { FORM_NAME } from '../../common';
import { useCadUploadState } from '../../useCadUpload';
import { useImageResolver } from '../../hooks';
import { useReport } from './hooks';

const styles = ({ palette, shadows, spacing, typography }) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        borderRadius: 0,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: palette.background.dark,
        boxShadow: shadows[0]
    },
    congratulations: {
        marginTop: spacing.unit * 5.5,
        fontWeight: typography.fontWeightBold,
        fontSize: '3.7vw',
        color: palette.common.black
    },
    uploadedContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginTop: spacing.unit,
        marginBottom: spacing.unit * 6,
        paddingLeft: spacing.unit * 30,
        paddingRight: spacing.unit * 30
    },
    uploaded: {
        fontWeight: typography.fontWeightLight,
        fontSize: '1.8vw',
        whiteSpace: 'nowrap',
        paddingRight: spacing.unit // Space
    },
    uploadedName: {
        color: palette.success.main,
        fontWeight: typography.fontWeightHeavy,
        fontSize: '1.8vw',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    buttonHeader: {
        fontSize: '1.25rem',
        fontWeight: typography.fontWeightBold,
        marginTop: spacing.unit * 4,
        marginBottom: spacing.unit * 2
    },
    button: {
        height: spacing.unit * 5.5,
        width: 'unset',
        fontSize: typography.subtitle1.fontSize,
        paddingLeft: spacing.unit * 3.5,
        paddingRight: spacing.unit * 3.5,
        marginRight: spacing.unit
    }
});

const mapState = state => ({
    cadReference: cadReferenceSelector(state),
    description: descriptionSelector(state),
    reportProps: reportPropsSelector(state),
    sizePaths: cadSizePathsSelector(state)
});

const mapDispatch = (dispatch, { mode }) => ({
    routeToNew: () => dispatch(routeToNew(mode)),
    routeToAmend: () => dispatch(routeToAmend(mode)),
    routeToSample: () => dispatch(routeToNewSample(mode)),
    setSnack: (...args) => dispatch(setSnack.apply(null, args))
});

const Complete = compose(
    connect(
        mapState,
        mapDispatch
    ),
    reduxForm({ form: FORM_NAME, destroyOnUnmount: false }),
    withStyles(styles)
)(
    ({
        classes: c,
        cadReference,
        description,
        routeToNew,
        routeToAmend,
        routeToSample,
        reportProps,
        setSnack,
        sizePaths
    }) => {
        const { reset } = useCadUploadState();
        const cadName = `${cadReference} ${description}`;

        const [handleExport, exportLoading, exportDisabled] = useReport({
            fileName: cadName,
            props: reportProps,
            setSnack
        });

        const handleRouteToNew = useCallback(() => {
            reset();
            routeToNew();
        }, [routeToNew, reset]);

        const handleRouteToAmend = useCallback(() => {
            reset();
            routeToAmend();
        }, [routeToAmend, reset]);

        const imageResolver = useImageResolver(sizePaths);

        const errorResolver = useCallback(
            error => setSnack({ message: error, type: 'error' }),
            [setSnack]
        );

        return (
            <Paper className={c.container}>
                <Typography className={c.congratulations} variant='h2'>
                    CONGRATULATIONS!
                </Typography>
                <div className={c.uploadedContainer}>
                    <Typography variant='h4' className={c.uploaded}>
                        You have successfully uploaded:
                    </Typography>
                    <Typography variant='h4' className={c.uploadedName}>
                        {cadName.toUpperCase()}
                    </Typography>
                </div>
                <VersionsViewer
                    readOnly
                    imageResolver={imageResolver}
                    errorResolver={errorResolver}
                >
                    <div>
                        <Typography variant='h5' className={c.buttonHeader}>
                            What would you like to do next?
                        </Typography>
                        <Grid container>
                            <Grid item>
                                <Button
                                    className={c.button}
                                    onClick={handleRouteToAmend}
                                >
                                    Add Another Version
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    className={c.button}
                                    color='success'
                                    onClick={() => routeToSample()}
                                >
                                    Sample This CAD
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    className={c.button}
                                    color='secondary'
                                    onClick={handleRouteToNew}
                                >
                                    Upload New CAD
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    className={c.button}
                                    color='primary'
                                    variant='outlined'
                                    disabled={exportDisabled}
                                    loading={exportLoading}
                                    onClick={handleExport}
                                >
                                    Export PDF
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </VersionsViewer>
            </Paper>
        );
    }
);

export default Complete;
