import React, { Fragment, useState } from 'react';
import clsx from 'classnames';
import { withStyles, Badge, CircularProgress } from '@material-ui/core';

import { deserializeFilename } from '@libs/filenames';
import { NO_IMAGE_URL } from 'common/common';
import useLoadedImage from '@libHooks/useLoadedImage';

import Slider from './Slider';

const styles = ({ spacing, palette }) => ({
    gridImageContainer: {
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        cursor: 'pointer',
        lineHeight: 0,

        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: palette.background.dark
    },
    gridImage: {
        width: '100%',
        height: '100%',
        maxHeight: spacing.unit * 7.5,
        objectFit: 'contain'
    },
    gridPlaceholderImage: {
        cursor: 'auto'
    },
    gridImageBadge: {
        top: spacing.unit * 1.5,
        right: spacing.unit * 1.25,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        color: palette.primary.contrastText
    },
    imageLoader: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }
});

const Image = withStyles(styles)(({ classes, column, row }) => {
    const {
        gridImageContainer,
        gridImage,
        gridPlaceholderImage,
        gridImageBadge,
        imageLoader
    } = classes;
    const images = row[column.name] || [];
    const imagesLength = images.length;
    const version1 = imagesLength > 0 && images[0].size100;
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const loadedImage = useLoadedImage(version1);

    const handleImageClick = e => {
        e.stopPropagation();
        setIsDialogOpen(true);
    };

    const handleCloseDialog = e => {
        e.stopPropagation();
        setIsDialogOpen(false);
    };

    const Wrapper = imagesLength > 1 ? Badge : Fragment;
    const wrapperProps =
        imagesLength > 1
            ? { badgeContent: imagesLength, classes: { badge: gridImageBadge } }
            : {};

    return (
        <div className={gridImageContainer} onClick={handleImageClick}>
            {version1 ? (
                <>
                    {loadedImage ? (
                        <Wrapper {...wrapperProps}>
                            <img
                                src={loadedImage}
                                alt={deserializeFilename(loadedImage)}
                                className={gridImage}
                                onDoubleClick={e => e.stopPropagation()}
                            />
                        </Wrapper>
                    ) : (
                        <div className={imageLoader}>
                            <CircularProgress
                                color='inherit'
                                size={20}
                                thickness={2}
                            />
                        </div>
                    )}
                </>
            ) : (
                <img
                    src={NO_IMAGE_URL}
                    alt='placeholder'
                    className={clsx(gridImage, gridPlaceholderImage)}
                    onClick={e => e.stopPropagation()}
                />
            )}
            {isDialogOpen && (
                <Slider images={images} handleClose={handleCloseDialog} />
            )}
        </div>
    );
});

export default Image;
