import { call } from 'redux-saga/effects';
import { apiRequest } from '@libs/apiRequest';
import { renameProperties } from '@libs/object';
import { normalizeDate } from '@baseComponents/Date';

export default function*(cadRef) {
    const {
        data: {
            data: [
                { amends, images, sizePaths, requestedDate, ...data } = {}
            ] = []
        } = {}
    } = yield call(apiRequest, {
        url: 'Cad/GetCadByCadRef',
        params: {
            cadRef
        }
    });

    const filteredData = Object.entries(data).reduce((acm, [key, value]) => {
        if (!!value) return { ...acm, [key]: value };
        else return acm;
    }, {});

    const renamedData = renameProperties(filteredData, {
        cadRef: 'cadReference',
        sleeve: 'sleeveType',
        developmentNo: 'developmentType',
        retailerId: 'retailer',
        genderId: 'gender',
        ageId: 'age',
        categoryId: 'category',
        subCategoryId: 'subCategory',
        styleId: 'style'
    });

    const versions = images
        .sort((a, b) => a.imageVersion.slice(1) - b.imageVersion.slice(1))
        .map(({ imageUrl }) => ({ url: imageUrl }));

    return {
        ...renamedData,
        versions,
        requestedDate: normalizeDate(requestedDate)
    };
}
