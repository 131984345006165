import { put, call } from 'redux-saga/effects';
import { change, initialize } from 'redux-form';
import { apiRequest } from '@libs/apiRequest';
import { FORM_NAME } from '../../Shape/common';
import { splice } from '@libs/object';
import { normalizeAttachments } from 'common/redux.normalizers';
import { setSnack } from 'utilities/redux.actions';
import { normalizeServerFailedResponse } from 'common/redux.normalizers';
import { normalizeDate } from '@baseComponents/Date';

import {
    normalizeBoughtDetails,
    normalizeSpecFabrics,
    normalizeFile,
    normalizeTrimDetails
} from '../../redux.normalizers';
import { INITIAL_VALUES } from '../../Shape/common';

export default function*(sampleRef, isCopy = false, isFabricOption = false) {
    try {
        const {
            data: {
                data: [
                    {
                        fabrics = [],
                        boughts = [],
                        trims = [],
                        attachments = [],
                        requestedDate,
                        sampleRequiredDate,
                        frontImage,
                        backImage,
                        departmentNumber,
                        ...data
                    } = {}
                ] = []
            } = {}
        } = yield call(apiRequest, {
            url: 'Sample/GetShapeSampleByRef',
            params: {
                sampleRef
            }
        });

        const nestedNormalizedData = {
            ...data,
            fabrics: normalizeSpecFabrics(fabrics, null, false, isCopy),
            boughts: normalizeBoughtDetails(boughts, isCopy),
            trims: normalizeTrimDetails(trims, isCopy),
            attachments: normalizeAttachments(attachments, isCopy),
            requestedDate:
                normalizeDate(requestedDate) || normalizeDate(new Date()),
            sampleRequiredDate: normalizeDate(sampleRequiredDate),
            frontImage: frontImage ? normalizeFile(frontImage) : '',
            backImage: backImage ? normalizeFile(backImage) : ''
        };

        const nonCopyDataMapping = {
            ...(isFabricOption || isCopy
                ? {}
                : { sampleRef: 'sampleReference', id: 'id' }),
            developmentNo: 'developmentNumber',
            retailerId: 'retailer',
            genderId: 'gender',
            ageId: 'age',
            categoryId: 'category',
            departmentId: 'department',
            styleId: 'style',
            designerFromId: 'designSource',
            shapeDesignerId: 'shapeDesigner',
            countryId: 'country'
        };

        const dateDataMapping = {
            requestedDate: 'dateRequested',
            sampleRequiredDate: 'dateRequiredBy'
        };

        const values = splice(nestedNormalizedData, {
            ...(isFabricOption || !isCopy ? nonCopyDataMapping : {}),
            ...(isFabricOption || isCopy ? {} : dateDataMapping),
            ukSizeId: 'sendToEcho',
            buyerSizeId: 'sendToBuyer',
            departmentContactPerson: 'buyer',
            isSampleRequired: 'photoApproval',
            specRef: 'specReference',
            garmentDescription: 'styleDescription', // came from ines and marion
            specComments: 'additionalComments',
            seasonId: 'season',
            fitId: 'fitOption',
            styleDescription: 'specDescription', // came from ines and marion
            specRefFrom: 'referenceFrom',
            sizeRangeId: 'sizeRange',
            necklineId: 'neckline',
            waistBandId: 'waistband',
            armholesCuffsId: 'armhole',
            hemlineId: 'hemline',
            labelTypeId: 'labelType',
            isPendingPrint: 'pendingPrint',
            frontImage: 'front',
            backImage: 'back',
            fabrics: 'fabrics',
            boughts: 'boughtDetails',
            trims: 'trimDetails',
            attachments: 'attachments'
        });

        yield put(
            initialize(
                FORM_NAME,
                { ...INITIAL_VALUES, ...values },
                isFabricOption || isCopy,
                {
                    updateUnregisteredFields: true,
                    keepDirty: isFabricOption || isCopy
                }
            )
        );

        // Only changed, not initialized
        if (departmentNumber)
            yield put(change(FORM_NAME, 'departmentNumber', departmentNumber));

        // Incomplete or Amend
        // Will trigger department cascade
        if (!isCopy && !departmentNumber) {
            const { department = null } = values;
            yield put(change(FORM_NAME, 'department', department));
        }
    } catch (error) {
        const serverMessage = yield call(normalizeServerFailedResponse, error);
        yield put(
            setSnack({
                message: `Failed to fetch data ${serverMessage}`,
                type: 'error',
                duration: 8000
            })
        );
    }
}
