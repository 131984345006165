import React from 'react';
import { withStyles } from '@material-ui/core';

const styles = ({ typography, spacing }) => ({
    message: {
        margin: 0,
        fontFamily: typography.fontFamily,
        fontSize: spacing.unit * 5,
        color: 'rgba(0,0,0,0.7)',
        paddingTop: spacing.unit * 12,

        textAlign: 'center'
    }
});

const Home = withStyles(styles)(({ classes: c }) => {
    return <h2 className={c.message}>Welcome to Echosys2.0</h2>;
});

export default Home;
