import { select, all } from 'redux-saga/effects';

import { renameProperties } from '@libs/object';

import { userIdSelector } from 'auth/redux.selectors';

import { developmentTypesSelector } from 'common/redux.selectors';
import { cadUploadFormSelector } from '../../redux.selectors';

import getDevelopmentTypeId from './getDevelopmentTypeId';

export default function*({ mode, id, cadReference }) {
    const userId = yield select(userIdSelector);
    const [{ developmentType, ...values }, developmentTypes] = yield all([
        select(
            cadUploadFormSelector,
            'buyerReference',
            'designer',
            'type',
            'description',
            'licensor',
            'property',
            'style',
            'sleeveType',
            'developmentType',
            'shapeDesigner',
            'artworkSource',
            'retailer',
            'department',
            'gender',
            'age',
            'category',
            'style'
        ),
        select(developmentTypesSelector, 'CAD')
    ]);

    const developmentTypeId = getDevelopmentTypeId(
        developmentTypes,
        developmentType,
        mode
    );

    return renameProperties(
        { ...values, userId, id, cadReference, developmentTypeId },
        {
            cadReference: 'cadRef',
            retailer: 'retailerId',
            developmentTypeId: 'developmentNo',
            sleeveType: 'sleeve',
            gender: 'genderId',
            age: 'ageId',
            category: 'categoryId',
            style: 'styleId'
        }
    );
}
