import { useCallback, useEffect, useState } from 'react';

import { usePDF } from '@libComponents/ReactPDF/PDFProvider';
import getDataUrl from '@libs/getDataUrl';

import PrintSamplesDocument from './PrintSamplesDocument';
import ShapeSamplesDocument from './ShapeSamplesDocument';

const loadProps = async rows => {
    const loadedProps = await Promise.all(
        rows.map(async ({ frontImage, backImage, ...row }) => {
            return {
                ...row,
                frontImage: frontImage[0].size350
                    ? await getDataUrl(frontImage[0].size350)
                    : null,
                backImage: backImage[0].size350
                    ? await getDataUrl(backImage[0].size350)
                    : null
            };
        })
    );

    // Each pdf page there will have 3 items.
    const pages = [...Array(Math.ceil(loadedProps.length / 3))];
    const pageItems = pages.map((page, idx) =>
        loadedProps.slice(idx * 3, idx * 3 + 3)
    );

    return {
        pages: pageItems
    };
};

export const useSamplePDF = ({ data, setSnack, sampleType, hasBoth }) => {
    const [pdfError, setPDFError] = useState(null);
    const [handleExport, exportLoading, exportDisabled] = usePDF({
        document:
            sampleType === 'P' ? PrintSamplesDocument : ShapeSamplesDocument,
        fileName: sampleType === 'P' ? 'printSamples.pdf' : 'shapeSamples.pdf',
        props: data,
        loadProps,
        errorResolver: error => setPDFError(error),
        generateOnChange: true
    });

    useEffect(() => {
        if (pdfError) {
            setSnack({
                message: pdfError,
                type: 'error',
                duration: 6000,
                action: {
                    label: 'Retry',
                    handle: handleExport
                }
            });
            setPDFError(null);
        }
    }, [pdfError]);

    const handlePDFExport = useCallback(() => {
        if (data.length === 0) {
            setSnack({
                message: `Please select a row to download.`,
                type: 'error',
                duration: 6000
            });
            return;
        }

        if (hasBoth) {
            setSnack({
                message: `You cannot download both PRINT and SHAPE types together.`,
                type: 'error',
                duration: 6000
            });
            return;
        }

        handleExport();
    }, [hasBoth, data, setSnack, handleExport]);

    return [handlePDFExport, exportLoading, exportDisabled];
};
