export default {
    id: 0,
    cadRef: 'string',
    buyerReference: 'string',
    categoryId: 0,
    styleId: 0,
    genderId: 0,
    ageId: 0,
    department: 0,
    developmentNo: 0,
    designer: 0,
    type: 0,
    description: 'string',
    licensor: 0,
    artworkSource: 0,
    property: 0,
    sleeve: 0,
    shapeDesigner: 0,
    isDiscontinue: true,
    userId: 0,
    retailerId: 0,
    versions: [
        {
            imageVersion: 'string',
            sizeOriginal: 'string',
            size100: 'string',
            size350: 'string',
            size800: 'string'
        }
    ]
};
