import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formValueSelector, reduxForm } from 'redux-form';

import { Divider, withStyles } from '@material-ui/core';

import ContentContainer from '@libComponents/ContentContainer';

// Libs
import {
    Checkbox,
    GridContainer,
    GridItem,
    Input,
    Date as DateInput
} from '@formComponents';
import { formListener } from 'app/ReduxGate/store/middleware/formMiddleware';

import {
    developmentNameSelector,
    developmentTypesLoadingSelector
} from 'common/redux.selectors';

// Local
import HeaderAdornment from '../HeaderAdornment';
import {
    SelectRequestTypes,
    SelectSendToEcho,
    SelectSendToBuyer,
    SelectSendToLicensor,
    FabricsContainer,
    SelectSpecReferences,
    RequestedDate,
    SampleSelect
} from '../../../components';
import {
    setSpecReferenceChildren,
    setSpecReferenceAndDescription,
    setSampleFitOptionsChildren
} from '../../../redux.actions';
import { isLicensedSelector } from '../../../redux.selectors';
import {
    DEVELOPED_FABRICS_FOR_PRINT,
    FIT_OPTIONS
} from '../../../redux.datasets';
import { FORM_NAME, LABELS } from '../../common';
import { useRequiredByMinDate } from './hooks';
import { usePrintState } from '../../usePrint';

const styles = ({ spacing }) => ({
    contentContainer: {
        paddingBottom: spacing.unit * 6
    },
    container: {
        paddingBottom: spacing.unit * 4
    },

    divider: {
        marginTop: spacing.unit * 3,
        marginBottom: spacing.unit * 1.5
    }
});

const mapState = state => {
    const developmentNumber = formValueSelector(FORM_NAME)(
        state,
        'developmentNumber'
    );

    return {
        developmentTypesLoading: developmentTypesLoadingSelector(state),
        developmentName: developmentNameSelector(
            state,
            'Print',
            developmentNumber
        ),
        isLicensed: isLicensedSelector(state, FORM_NAME),
        dateRequested: formValueSelector(FORM_NAME)(state, 'dateRequested')
    };
};

const mapInputs = {
    requestType: ({ payload }) => setSpecReferenceAndDescription(payload),
    specReference: ({ payload }) => setSpecReferenceChildren(payload),
    fitOption: ({ payload }) => setSampleFitOptionsChildren(payload, FORM_NAME)
};

const Details = compose(
    connect(
        mapState,
        null
    ),
    reduxForm({ form: FORM_NAME, destroyOnUnmount: false }),
    formListener(mapInputs),
    withStyles(styles)
)(
    ({
        classes: c,
        dateRequested,
        developmentName,
        developmentTypesLoading,
        isLicensed
    }) => {
        const requiredByMinDate = useRequiredByMinDate(dateRequested);
        const { sampleRefLoading } = usePrintState();
        return (
            <ContentContainer
                title={LABELS.details}
                styles={{ container: c.contentContainer }}
                AdornmentComponent={<HeaderAdornment />}
            >
                <GridContainer className={c.container}>
                    <GridItem md={4}>
                        <Input
                            name='sampleReference'
                            label='Sample reference'
                            disabled
                            loading={sampleRefLoading}
                            required
                        />
                    </GridItem>
                    <GridItem md={4}>
                        <Input
                            name='developmentName'
                            label='Development no'
                            staticValue={developmentName}
                            loading={developmentTypesLoading}
                            disabled
                        />
                    </GridItem>
                    <GridItem md={4}>
                        <SelectRequestTypes />
                    </GridItem>
                    <GridItem md={4}>
                        <RequestedDate />
                    </GridItem>
                    <GridItem md={4}>
                        <DateInput
                            name='dateRequiredBy'
                            label='Sample required by'
                            minDate={requiredByMinDate}
                            required
                        />
                    </GridItem>
                    <GridItem md={4}>
                        <SampleSelect
                            name='fitOption'
                            label='Fit Options'
                            placeholder='Select fit option'
                            entity={FIT_OPTIONS}
                        />
                    </GridItem>
                    <GridItem md={4}>
                        <SelectSendToEcho required />
                    </GridItem>
                    <GridItem md={4}>
                        <SelectSendToBuyer required />
                    </GridItem>
                    <GridItem md={4}>
                        <Input name='buyer' label='Buyer name' />
                    </GridItem>
                    {isLicensed && (
                        <GridItem md={4}>
                            <SelectSendToLicensor
                                formname={FORM_NAME}
                                required
                            />
                        </GridItem>
                    )}
                    <GridItem md={4}>
                        <Checkbox
                            name='photoApproval'
                            label='Request For Photo Approval'
                        />
                    </GridItem>
                    <GridItem md={12}>
                        <Divider className={c.divider} />
                    </GridItem>
                    <GridItem md={4}>
                        <SelectSpecReferences required />
                    </GridItem>
                    <GridItem md={8}>
                        <Input
                            name='specDescription'
                            label='Spec description'
                            required
                        />
                    </GridItem>
                </GridContainer>
                <FabricsContainer
                    entity={DEVELOPED_FABRICS_FOR_PRINT}
                    required
                />
            </ContentContainer>
        );
    }
);

export default Details;
