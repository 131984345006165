import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';

import { withStyles, Typography } from '@material-ui/core';

import { Xlsx as XlsxIcon } from '@assets/Icons';

const styles = ({ spacing, palette }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '&$disabled': {
            pointerEvents: 'none',
            cursor: 'none'
        },
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    title: {
        fontSize: spacing.unit * 1.5,
        color: palette.secondary.main,
        marginLeft: spacing.unit,
        '&$disabled': {
            color: palette.grey[500]
        }
    },
    disabled: {}
});

const propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

const GridXlsxExport = withStyles(styles)(
    ({ classes: c, title, className, loading, ...restProps }) => {
        return (
            <div
                className={clsx(c.root, className, loading && c.disabled)}
                {...restProps}
            >
                <XlsxIcon color={`${loading ? '#9e9e9e' : ''}`} />
                <Typography className={clsx(c.title, loading && c.disabled)}>
                    {title}
                </Typography>
            </div>
        );
    }
);

GridXlsxExport.propTypes = propTypes;

export default GridXlsxExport;
