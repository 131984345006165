import { initializeDatasets } from '@libs/datasets';
import { LOGOUT } from '../auth/redux.actionTypes';
import { RESET_DATASETS } from '@libs/datasets/libs.actionTypes';

import { SET_REDIRECT } from './redux.actionTypes';
import datasets from './redux.datasets';

const initialState = {
    isRedirected: false,
    ...initializeDatasets(datasets)
};

export default (state = initialState, { type, payload, ...action }) => {
    switch (type) {
        case SET_REDIRECT: {
            return {
                ...state,
                isRedirected: payload
            };
        }
        case LOGOUT:
        case RESET_DATASETS: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};
