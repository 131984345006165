import React from 'react';

import {
    NEW_CAD_ROUTE_ID,
    INCOMPLETE_CAD_ROUTE_ID,
    AMEND_CAD_ROUTE_ID
} from './common';
import { CadUpload } from './index';

export default {
    [NEW_CAD_ROUTE_ID]: () => <CadUpload />,
    [INCOMPLETE_CAD_ROUTE_ID]: () => <CadUpload mode='incomplete' />,
    [AMEND_CAD_ROUTE_ID]: () => <CadUpload mode='amend' />
};
