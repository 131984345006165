import React from 'react';

import { PieChart } from '@libComponents/Charts';
import ContentContainer from '@libComponents/ContentContainer';

import Legend from './Legend';
import useDynamicColors from '../../hooks';

const BreakdownChart = ({ chartName, data }) => {
    const colors = useDynamicColors(data);
    return (
        <ContentContainer title={chartName}>
            <PieChart
                data={data}
                colors={colors}
                legendRenderer={Legend}
                outerRadius={150}
                innerRadius={40}
                layout='vertical'
                align='right'
                verticalAlign='middle'
            />
        </ContentContainer>
    );
};

export default BreakdownChart;
