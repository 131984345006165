import { REDIRECT_TO_ROUTE, SET_REDIRECT } from './redux.actionTypes';

export const redirectToRoute = routeId => ({
    type: REDIRECT_TO_ROUTE,
    payload: { routeId }
});

export const setRedirect = redirect => ({
    type: SET_REDIRECT,
    payload: redirect
});
