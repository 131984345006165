import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import SelectModel from '@formComponents/SelectModel';

import {
    samplingReportDataSelector,
    samplingReportDataLoadingSelector
} from '../../../redux.selectors';

const retailersSelector = createSelector(
    ({ overall = [] }) => overall,
    retailers => retailers.map(({ id, name }) => ({ label: name, value: id }))
);

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'retailer',
    label: 'Retailer',
    placeholder: 'Select retailer',
    disabled: false,
    required: false
};

const mapState = state => {
    const samplingReportData = samplingReportDataSelector(state) || {};
    return {
        data: retailersSelector(samplingReportData),
        loading: samplingReportDataLoadingSelector(state)
    };
};

const SelectRetailer = connect(mapState)(props => {
    return <SelectModel {...props} />;
});

SelectRetailer.propTypes = propTypes;
SelectRetailer.defaultProps = defaultProps;

export default SelectRetailer;
