import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';

import { withStyles, Typography } from '@material-ui/core';

import { functionalComponentPropType } from '@libs/propTypes';

const styles = ({ spacing, palette }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '&$disabled': {
            pointerEvents: 'none',
            cursor: 'none'
        },
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    title: {
        fontSize: spacing.unit * 1.5,
        color: palette.secondary.main,
        marginLeft: spacing.unit,
        '&$disabled': {
            color: palette.grey[500]
        }
    },
    disabled: {}
});

const propTypes = {
    Icon: functionalComponentPropType.isRequired,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

const ExportTitleAndIcon = withStyles(styles)(
    ({ classes: c, Icon, title, className, disabled, ...restProps }) => {
        return (
            <div
                className={clsx(c.root, className, disabled && c.disabled)}
                {...restProps}
            >
                <Icon color={`${disabled ? '#9e9e9e' : ''}`} />
                <Typography className={clsx(c.title, disabled && c.disabled)}>
                    {title}
                </Typography>
            </div>
        );
    }
);

ExportTitleAndIcon.propTypes = propTypes;

export default ExportTitleAndIcon;
