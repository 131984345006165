import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { BlobProvider } from '@react-pdf/renderer';

import { usePDFContext } from './Provider';

export default () => {
    const [
        { document: Document, fileName, props, loadProps },
        dispatch
    ] = usePDFContext();
    const [propsReady, setPropsReady] = useState(false);
    const [loadedProps, setLoadedProps] = useState(null);
    const [blobPayload, setBlobPayload] = useState({});
    const [url, setUrl] = useState(undefined);
    const [updateCount, setUpdateCount] = useState(0);

    const handleUpdate = useCallback(() => setUpdateCount(updateCount + 1), [
        updateCount
    ]);

    const handleLoadProps = useCallback(async () => {
        try {
            const loadedProps = await loadProps(props);
            setLoadedProps(loadedProps);
            setPropsReady(true);
        } catch {
            dispatch({ type: 'removeDocument', payload: true });
        }
    }, [props, loadProps, setLoadedProps, setPropsReady, dispatch]);

    useEffect(() => {
        if (Document !== null) handleLoadProps();
    }, [Document, handleLoadProps]);

    useEffect(() => {
        let timeout = null;
        clearTimeout(timeout);

        const { url: blobUrl, loading } = blobPayload;

        if (!loading && blobUrl !== url) {
            timeout = setTimeout(() => {
                setUrl(blobUrl);
                handleUpdate();
            }, 250);
        }

        return () => clearTimeout(timeout);
    }, [blobPayload, url, handleUpdate]);

    useEffect(() => {
        if (url && fileName && updateCount === 1) {
            const anchor = window.document.createElement('a');
            anchor.download = fileName;
            anchor.href = url;
            anchor.click();
            dispatch({ type: 'removeDocument' });
            setUpdateCount(0);
            setPropsReady(false);
            setLoadedProps(null);
        }
    }, [url, fileName, updateCount]);

    return propsReady && Document ? (
        <BlobProvider document={<Document {...loadedProps} />}>
            {blobPayload => {
                setTimeout(() => setBlobPayload(blobPayload), 1);
                return <Fragment />;
            }}
        </BlobProvider>
    ) : (
        <Fragment />
    );
};
