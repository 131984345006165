import React from 'react';
import PropTypes from 'prop-types';

import { withStyles, Typography } from '@material-ui/core';

import { composeClsx } from '@libs/materialUI';

const propTypes = {
    color: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.number,
    separator: PropTypes.bool
};

const defaultProps = {
    styles: {},
    separator: false
};

const styles = ({ spacing, palette }) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: spacing.unit * 1.5
    },
    colorBox: {
        width: spacing.unit * 2.5,
        height: spacing.unit * 2.5,
        marginRight: spacing.unit
    },
    value: {
        marginRight: spacing.unit
    },
    name: {
        maxWidth: spacing.unit * 12.5,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    separator: {
        width: '2px',
        height: spacing.unit * 2.5,
        backgroundColor: palette.grey[300],
        margin: `0 ${spacing.unit * 1.5}px`
    }
});

const LegendItem = ({
    classes,
    color,
    name,
    value,
    styles,
    separator,
    ...rest
}) => {
    const c = composeClsx({ classes, styles });
    return (
        <div className={c.container} {...rest}>
            <div
                className={c.colorBox}
                style={{ backgroundColor: color }}
            ></div>
            <Typography className={c.value} variant='body2'>
                {value}
            </Typography>
            <Typography variant='body2' className={c.name}>
                {name}
            </Typography>
            {separator && <div className={c.separator}></div>}
        </div>
    );
};

LegendItem.propTypes = propTypes;

LegendItem.defaultProps = defaultProps;

const _LegendItem = withStyles(styles)(LegendItem);

export { _LegendItem as default, _LegendItem as LegendItem };
