import React from 'react';

import { RADIAN } from './constants';

const ValueLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text
            x={x}
            y={y}
            fill='white'
            textAnchor='middle'
            dominantBaseline='middle'
        >
            {value}
        </text>
    );
};

export { ValueLabel as default, ValueLabel };
