import React, { useMemo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { change, formValueSelector } from 'redux-form';

import { withStyles, Typography } from '@material-ui/core';

import { LegendItem } from '@libComponents/Charts';

import { FORM_NAME } from '../common';

const styles = ({ spacing, palette }) => ({
    root: {
        width: '90%',
        margin: 'auto',
        marginTop: spacing.unit * 2
    },
    legendContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    itemContainer: {
        '&:hover': {
            cursor: 'pointer',
            '& $value': {
                color: palette.orange.main
            },
            '& $name': {
                color: palette.orange.main
            }
        }
    },
    total: {
        fontSize: spacing.unit * 2,
        color: '#B71C1C',
        textAlign: 'center',
        marginBottom: spacing.unit * 1.5
    },
    name: {},
    nameHighlighted: {
        color: palette.orange.dark
    },
    value: {},
    valueHighlighted: {
        color: palette.orange.dark
    }
});

const mapStateToProps = state => ({
    retailer: formValueSelector(FORM_NAME)(state, 'retailer')
});

const mapDispatchToProps = dispatch => ({
    onLegendItemClick: retailerId => () =>
        dispatch(change(FORM_NAME, 'retailer', retailerId))
});

const Legend = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles)
)(({ classes: c, payload, retailer, onLegendItemClick }) => {
    const totalValue = useMemo(
        () => payload.reduce((sum, item) => sum + item.payload.value, 0),
        [payload]
    );

    return (
        <div className={c.root}>
            <Typography className={c.total} variant='body1'>
                Total: {totalValue}
            </Typography>
            <div className={c.legendContainer}>
                {payload.map((item, index) => (
                    <LegendItem
                        key={item.value}
                        color={item.color}
                        name={item.value}
                        value={item.payload.value}
                        separator={index < payload.length - 1}
                        styles={{
                            container: c.itemContainer,
                            value:
                                retailer === item.payload.id
                                    ? c.valueHighlighted
                                    : c.value,
                            name:
                                retailer === item.payload.id
                                    ? c.nameHighlighted
                                    : c.name
                        }}
                        onClick={onLegendItemClick(item.payload.id)}
                    />
                ))}
            </div>
        </div>
    );
});

export default Legend;
