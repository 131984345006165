import React from 'react';
import PropTypes from 'prop-types';

import { Button as MaterialButton, LinearProgress } from '@material-ui/core';

import { withStyles } from '@material-ui/core';

import { composeClsx } from '@libs/materialUI';
import { useSwitchColor } from './hooks';
import Loader from './Loader';

const propTypes = {
    children: PropTypes.node,
    styles: PropTypes.object,
    variant: PropTypes.string,
    color: PropTypes.oneOf([
        'primary',
        'secondary',
        'success',
        'warning',
        'error',
        'orange'
    ]),
    loading: PropTypes.bool
};

const defaultProps = {
    styles: {},
    variant: 'contained',
    color: 'primary',
    loading: false
};

const styles = ({ palette, spacing, typography, shadows }) => ({
    root: {
        height: spacing.unit * 4,
        width: spacing.unit * 15,

        fontWeight: typography.fontWeightRegular,
        textTransform: 'none',
        boxShadow: shadows[0]
    },
    contained: {
        color: palette.primary.contrastText,
        backgroundColor: 'var(--color)',
        '&:hover': {
            backgroundColor: 'var(--hoverColor)'
        },

        '&$disabled': {
            backgroundColor: 'var(--disabledColor)',
            color: palette.grey[200],
            '&:hover': {
                backgroundColor: 'var(--disabledColor)'
            }
        }
    },
    outlined: {
        backgroundColor: palette.primary.contrastText,
        color: 'var(--color)',
        borderColor: 'var(--color)',

        '&:hover': {
            backgroundColor: 'var(--outlinedHoverColor)',
            borderColor: 'var(--hoverColor)'
        },

        '&$disabled': {
            backgroundColor: 'var(--disabledColor)',
            color: palette.grey[200],
            '&:hover': {
                backgroundColor: 'var(--disabledColor)'
            }
        }
    },
    label: {
        fontSize: '90%',
        fontWeight: typography.fontWeightRegular
    },
    disabled: {}
});

const Button = withStyles(styles, { withTheme: true })(
    ({
        classes: { progressRoot, progressBarColorPrimary, ...classes },
        styles,
        children,
        color,
        theme,
        loading,
        disabled,
        ...restProps
    }) => {
        return (
            <MaterialButton
                classes={composeClsx({ classes, styles })}
                style={useSwitchColor(theme, color)}
                disabled={disabled || loading}
                {...restProps}
            >
                {children}
                {!disabled && loading && <Loader />}
            </MaterialButton>
        );
    }
);

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export { Button as default, Button };
