import { takeLatest } from 'redux-saga/effects';

import {
    ADD_RETAILER,
    ADD_DEPARTMENT,
    ADD_STYLE,
    ADD_GENDER,
    ADD_CATEGORY,
    ADD_COUNTRY,
    ADD_AGE
} from 'setup/redux.actionTypes';

import retailerWorker from './retailerWorker.js';
import departmentWorker from './departmentWorker.js';
import styleWorker from './styleWorker.js';
import genderWorker from './genderWorker.js';
import categoryWorker from './categoryWorker.js';
import countryWorker from './countryWorker.js';
import ageWorker from './ageWorker.js';

// Watchers
export default function*() {
    yield takeLatest(ADD_RETAILER, retailerWorker);
    yield takeLatest(ADD_DEPARTMENT, departmentWorker);
    yield takeLatest(ADD_STYLE, styleWorker);
    yield takeLatest(ADD_GENDER, genderWorker);
    yield takeLatest(ADD_CATEGORY, categoryWorker);
    yield takeLatest(ADD_COUNTRY, countryWorker);
    yield takeLatest(ADD_AGE, ageWorker);
}
