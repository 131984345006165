import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import { LegendItem } from '@libComponents/Charts';

const styles = ({ spacing }) => ({
    legendContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    colorBox: {
        width: spacing.unit * 2,
        height: spacing.unit * 2,
        marginRight: spacing.unit * 0.75
    },
    legendName: {
        maxWidth: spacing.unit * 12,
        fontSize: spacing.unit * 1.5,
        marginRight: spacing.unit * 1
    },
    legendValue: {
        fontSize: spacing.unit * 1.5,
        marginRight: spacing.unit * 0.75
    }
});

const Legend = ({ classes: c, payload }) => (
    <div className={c.legendContainer}>
        {payload.map((item, index) => (
            <LegendItem
                key={item.value}
                color={item.color}
                name={item.value}
                value={item.payload.value}
                styles={{
                    colorBox: c.colorBox,
                    value: c.legendValue,
                    name: c.legendName
                }}
            />
        ))}
    </div>
);

export default withStyles(styles)(Legend);
