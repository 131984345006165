import { useMemo } from 'react';

// Function to calculate relative luminance
const getLuminance = (r, g, b) => {
    const a = [r, g, b].map(v => {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

// Function to calculate contrast ratio between two colors
const getContrastRatio = (color1, color2) => {
    const luminance1 = getLuminance(...color1);
    const luminance2 = getLuminance(...color2);
    const brightest = Math.max(luminance1, luminance2);
    const darkest = Math.min(luminance1, luminance2);
    return (brightest + 0.05) / (darkest + 0.05);
};

// Function to convert HSL to RGB
const hslToRgb = (h, s, l) => {
    s /= 100;
    l /= 100;
    const c = (1 - Math.abs(2 * l - 1)) * s;
    const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    const m = l - c / 2;
    let r = 0,
        g = 0,
        b = 0;

    if (0 <= h && h < 60) {
        r = c;
        g = x;
        b = 0;
    } else if (60 <= h && h < 120) {
        r = x;
        g = c;
        b = 0;
    } else if (120 <= h && h < 180) {
        r = 0;
        g = c;
        b = x;
    } else if (180 <= h && h < 240) {
        r = 0;
        g = x;
        b = c;
    } else if (240 <= h && h < 300) {
        r = x;
        g = 0;
        b = c;
    } else if (300 <= h && h < 360) {
        r = c;
        g = 0;
        b = x;
    }

    r = Math.round((r + m) * 255);
    g = Math.round((g + m) * 255);
    b = Math.round((b + m) * 255);

    return [r, g, b];
};

// Function to generate a color based on an index and ensure it has good contrast with white
const generateColor = (index, total) => {
    const hue = Math.floor((360 / total) * index); // Distribute colors evenly on the hue spectrum
    const saturation = 70; // Fixed saturation to ensure vivid colors
    let lightness = 50; // Adjust lightness to ensure good contrast with white text
    let color;
    let contrastRatio;

    do {
        color = hslToRgb(hue, saturation, lightness);
        contrastRatio = getContrastRatio(color, [255, 255, 255]); // Compare with white
        lightness -= 5; // Decrease lightness if contrast is not enough
    } while (contrastRatio < 4.5 && lightness > 10); // 4.5:1 contrast ratio is a good standard for readability

    return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
};

// Custom hook to generate colors dynamically
const useDynamicColors = data => {
    return useMemo(() => {
        const colorMap = {};
        data.forEach((item, index) => {
            const color = generateColor(index, data.length);
            colorMap[item.name] = color;
        });
        return colorMap;
    }, [data]);
};

export default useDynamicColors;
