import { useState, useCallback, useMemo } from 'react';

export default data => {
    const [selectedRows, setSelectedRows] = useState([]);

    const selectRow = useCallback(
        rowId => {
            // Add the row ID to the selectedRows array if it's not already selected
            if (!selectedRows.includes(rowId)) {
                setSelectedRows(prevSelectedRows => [
                    ...prevSelectedRows,
                    rowId
                ]);
            }
        },
        [selectedRows]
    );

    const unselectRow = useCallback(
        // Remove the row ID from the selectedRows array
        rowId => {
            setSelectedRows(prevSelectedRows =>
                prevSelectedRows.filter(row => row !== rowId)
            );
        },
        [setSelectedRows]
    );

    const toggleRowSelection = useCallback(
        rowId => {
            // Toggle the selection state of a row
            if (selectedRows.includes(rowId)) {
                unselectRow(rowId);
            } else {
                selectRow(rowId);
            }
        },
        [selectRow, unselectRow]
    );

    const selectAllRows = useCallback(() => {
        // Select all rows by setting the selectedRows state to contain all row IDs
        const allRowIds = data.map(row => row.id);
        setSelectedRows(allRowIds);
    }, [data]);

    const unselectAllRows = useCallback(() => {
        // Clear the selectedRows state to unselect all rows
        setSelectedRows([]);
    }, []);

    const isRowSelected = useCallback(
        rowId => {
            return selectedRows.includes(rowId);
        },
        [selectedRows]
    );

    const isAllRowsSelected = useMemo(() => {
        return (
            data.length > 0 && data.every(({ id }) => selectedRows.includes(id))
        );
    }, [data, selectedRows]);

    const isPartiallySelected = useMemo(() => {
        return data.some(({ id }) => selectedRows.includes(id));
    }, [data, selectedRows]);

    const toggleAllRowSelection = useCallback(() => {
        // Toggle the selection state of all rows based on conditions
        if (isPartiallySelected || isAllRowsSelected) {
            unselectAllRows();
        } else {
            selectAllRows();
        }
    }, [
        isPartiallySelected,
        isAllRowsSelected,
        unselectAllRows,
        selectAllRows
    ]);

    return {
        selectedRows,
        toggleRowSelection,
        toggleAllRowSelection,
        isRowSelected,
        isAllRowsSelected,
        isPartiallySelected
    };
};
