import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, change, formValueSelector } from 'redux-form';

import { withStyles } from '@material-ui/core';

// Libs
import Button from '@libComponents/Button';
import ContentContainer from '@libComponents/ContentContainer';
import { fetchDatasets } from '@libs/datasets';
import { GridContainer, GridItem, Checkbox } from '@formComponents';
import { DEVELOPMENT_TYPES } from 'common/redux.datasets';
import {
    SelectGenders,
    SelectAges,
    SelectCategories,
    SelectStyles
} from 'common/components';
import { setGenderChildren as setCadGenderChildren } from 'common/redux.actions';
import { formListener } from 'app/ReduxGate/store/middleware';
import { SelectRetailers, SelectDepartments } from 'sampling/components';

// Local
import {
    SelectTypes,
    SelectDesigners,
    SelectLicensors,
    SelectProperties
} from '../components';
import { FORM_NAME } from '../common';
import { useCadUploadState } from '../useCadUpload';
import { isLicensedSelector } from '../redux.selectors';
import {
    setCadRetailerChildren,
    setCadPropertyChildren,
    setArtworkSource
} from '../redux.actions';

const styles = ({ spacing, typography }) => ({
    container: {
        paddingTop: spacing.unit * 1,
        paddingBottom: spacing.unit * 6
    },
    checkboxContainer: {
        paddingBottom: 0
    },
    checkboxLabel: { fontSize: typography.caption.fontSize },
    button: {
        height: '100%',
        fontSize: typography.subtitle1.fontSize
    }
});

const mapState = state => ({
    isLicensed: isLicensedSelector(state),
    hasOnBehalfOf: formValueSelector(FORM_NAME)(state, 'hasOnBehalfOf')
});

const mapDispatch = {
    fetchDatasets
};

const mapInputs = {
    type: () => change(FORM_NAME, 'licensor', ''),
    retailer: setCadRetailerChildren,
    gender: ({ payload }) => setCadGenderChildren(payload, FORM_NAME),
    licensor: setCadPropertyChildren,
    designer: setArtworkSource
};

const BasicInformation = compose(
    connect(
        mapState,
        mapDispatch
    ),
    reduxForm({
        form: FORM_NAME,
        destroyOnUnmount: false
    }),
    formListener(mapInputs),
    withStyles(styles)
)(({ classes: c, isLicensed, hasOnBehalfOf, fetchDatasets }) => {
    useEffect(() => {
        fetchDatasets(DEVELOPMENT_TYPES);
    }, []);
    const { canSave, handleSave } = useCadUploadState();

    return (
        <ContentContainer
            title='Enter basic information'
            AdornmentComponent={
                <Button
                    className={c.button}
                    color='success'
                    disabled={!canSave}
                    onClick={handleSave}
                >
                    Save Draft
                </Button>
            }
        >
            <GridContainer className={c.checkboxContainer}>
                <GridItem md={6}>
                    <Checkbox
                        name='hasOnBehalfOf'
                        label='Create on Behalf of Another'
                        styles={{
                            label: c.checkboxLabel
                        }}
                    />
                </GridItem>
            </GridContainer>
            <GridContainer className={c.container}>
                <GridItem md={4}>
                    <SelectTypes required />
                </GridItem>
                {isLicensed && (
                    <Fragment>
                        <GridItem md={4}>
                            <SelectLicensors required />
                        </GridItem>
                        <GridItem md={4}>
                            <SelectProperties formname={FORM_NAME} required />
                        </GridItem>
                    </Fragment>
                )}
                <GridItem md={4}>
                    <SelectDesigners required disabled={!hasOnBehalfOf} />
                </GridItem>
                <GridItem md={4}>
                    <SelectRetailers required />
                </GridItem>
                <GridItem md={4}>
                    <SelectGenders required />
                </GridItem>
                <GridItem md={4}>
                    <SelectAges required />
                </GridItem>
                <GridItem md={4}>
                    <SelectCategories required />
                </GridItem>
                <GridItem md={4}>
                    <SelectDepartments required />
                </GridItem>
                <GridItem md={4}>
                    <SelectStyles required />
                </GridItem>
            </GridContainer>
        </ContentContainer>
    );
});

export default BasicInformation;
