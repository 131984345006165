import React, { useState } from 'react';
import {
    withStyles,
    Dialog,
    DialogContent,
    IconButton,
    CircularProgress
} from '@material-ui/core';
import { ArrowBack, ArrowForward, Close } from '@material-ui/icons';

import { deserializeFilename } from '@libs/filenames';
import useLoadedImage from '@libHooks/useLoadedImage';

const styles = ({ spacing, palette, shape }) => ({
    gridSliderContent: {
        position: 'relative',
        padding: 0,
        '&:first-child': {
            padding: 0
        }
    },
    gridSliderCloseButton: {
        position: 'absolute',
        right: 0,
        zIndex: 1,

        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: palette.background.dark,

        backgroundColor: palette.grey[200],
        padding: spacing.unit * 0.5,
        '&:hover': {
            backgroundColor: palette.grey[300]
        }
    },
    gridSliderImageWrapper: {
        position: 'relative',
        lineHeight: 0,
        width: spacing.unit * 100,
        height: spacing.unit * 75
    },
    gridSliderImage: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },
    gridSliderNavigation: {
        position: 'absolute',
        top: '50%',
        zIndex: 1,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    gridSliderNavigationButton: {
        marginLeft: spacing.unit,
        marginRight: spacing.unit,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: palette.background.dark,

        backgroundColor: palette.primary.contrastText,
        padding: spacing.unit * 0.75,
        '&:hover': {
            backgroundColor: palette.grey[300]
        }
    },
    gridSliderInfo: {
        position: 'absolute',
        bottom: spacing.unit * 2.5,
        left: '50%',
        transform: 'translateX(-50%)', // Center the element horizontally
        backgroundColor: palette.grey[200],
        padding: spacing.unit * 1,
        display: 'flex',
        alignItems: 'center',
        borderRadius: shape.borderRadius
    },
    gridSliderName: {
        maxWidth: spacing.unit * 37.5,
        lineHeight: 'initial',
        fontSize: spacing.unit * 1.75,
        color: palette.grey[600],
        marginRight: spacing.unit * 4
    },
    gridSliderNumber: {
        fontSize: spacing.unit * 2,
        color: palette.grey[600],
        fontWeight: 'bold'
    },
    imageLoader: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }
});

const Slider = withStyles(styles)(({ images, handleClose, classes: c }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const totalImages = images.length;
    const showControls = totalImages > 1;
    const currentImagePath = images[currentImageIndex].size800;
    const currentImageName =
        images[currentImageIndex].imageVersion ||
        deserializeFilename(currentImagePath);
    const loadedImage = useLoadedImage(currentImagePath);

    const handlePrevImage = () => {
        setCurrentImageIndex(prevIndex =>
            prevIndex > 0 ? prevIndex - 1 : totalImages - 1
        );
    };

    const handleNextImage = () => {
        setCurrentImageIndex(prevIndex =>
            prevIndex < totalImages - 1 ? prevIndex + 1 : 0
        );
    };

    return (
        <Dialog open={true} onClose={handleClose} maxWidth='md'>
            <DialogContent className={c.gridSliderContent}>
                <IconButton
                    className={c.gridSliderCloseButton}
                    onClick={handleClose}
                >
                    <Close />
                </IconButton>
                <div className={c.gridSliderImageWrapper}>
                    {loadedImage ? (
                        <img
                            className={c.gridSliderImage}
                            src={loadedImage}
                            alt={currentImageName}
                        />
                    ) : (
                        <div className={c.imageLoader}>
                            <CircularProgress
                                color='inherit'
                                size={50}
                                thickness={2}
                            />
                        </div>
                    )}

                    {showControls && (
                        <div className={c.gridSliderNavigation}>
                            <IconButton
                                className={c.gridSliderNavigationButton}
                                onClick={handlePrevImage}
                            >
                                <ArrowBack />
                            </IconButton>
                            <IconButton
                                className={c.gridSliderNavigationButton}
                                onClick={handleNextImage}
                            >
                                <ArrowForward />
                            </IconButton>
                        </div>
                    )}

                    <div className={c.gridSliderInfo}>
                        <span className={c.gridSliderName}>
                            {currentImageName}
                        </span>
                        <span className={c.gridSliderNumber}>
                            {currentImageIndex + 1} / {totalImages}
                        </span>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
});

export default Slider;
