import React from 'react';

// Material
import { withStyles, Grid } from '@material-ui/core';

// Libs
import ContentContainer from '@libComponents/ContentContainer';
import { GridItem } from '@formComponents';

import RetailerBreakdownSelector from './RetailerBreakdownSelector';
import BreakdownChart from './BreakdownChart';
import NoDataMessage from '../NoDataMessage';

const styles = ({ spacing }) => ({
    container: {
        width: '100%',
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    gridItem: {
        padding: spacing.unit * 1.5
    }
});

const SampleDetailsBreakdownChart = withStyles(styles)(
    ({ classes: c, data }) => {
        return (
            <ContentContainer
                title='Breakdown'
                AdornmentComponent={<RetailerBreakdownSelector />}
                styles={{ container: c.container }}
            >
                {data && Object.keys(data).length > 0 ? (
                    <Grid container>
                        {Object.entries(data).map(([key, value]) => (
                            <GridItem key={key} md={6} className={c.gridItem}>
                                <BreakdownChart chartName={key} data={value} />
                            </GridItem>
                        ))}
                    </Grid>
                ) : (
                    <NoDataMessage />
                )}
            </ContentContainer>
        );
    }
);

export default SampleDetailsBreakdownChart;
