import { put, takeLatest, select, call } from 'redux-saga/effects';
import {
    change,
    destroy,
    formValueSelector,
    initialize,
    startSubmit,
    stopSubmit
} from 'redux-form';
import _ from 'lodash';

import { isRedirectedSelector } from 'navigation/redux.selectors';
import { redirectToRoute, setRedirect } from 'navigation/redux.actions';
import {
    PRINT_PUBLISH,
    GET_VERSIONS,
    SET_SPEC_REFERENCE_CHILDREN,
    DESTROY_PRINT_FORM,
    GET_INCOMPLETE_PRINT_DATA,
    GET_AMEND_PRINT_DATA,
    GET_COPY_PRINT_DATA,
    ROUTE_TO_PRINT,
    SET_PRINT_RETAILER_CHILDREN,
    SET_SPEC_REFERENCE_AND_DESCRIPTION,
    PRINT_REFERENCE_GENERATE
} from '../../redux.actionTypes';
import apiRequest from '@libs/apiRequest';

import {
    setVersions,
    setFabricsLoading,
    destroyPrintForm
} from '../../redux.actions';
import {
    FORM_NAME,
    INITIAL_VALUES,
    NEW_PRINT_ROUTE_ID,
    AMEND_PRINT_ROUTE_ID,
    INCOMPLETE_PRINT_ROUTE_ID
} from '../../Print/common';
import {
    fabricForMainBodyExistsSelector,
    specDescriptionSelector,
    specReportURLSelector
} from '../../redux.selectors';
import { normalizeSpecFabrics } from '../../redux.normalizers';

import printPublishWorker from './printPublishWorker';
import getReferenceDataWorker from './getReferenceDataWorker';
import { setLoading } from '../../../utilities/redux.actions';
import isBlobUrl from '../../../@libs/isBlobUrl';
import generatePrintReferenceWorker from './generatePrintReferenceWorker';

// Workers
export const routeToPrintWorker = function*({ payload: { mode = null } = {} }) {
    const routeID =
        mode === 'new'
            ? NEW_PRINT_ROUTE_ID
            : mode === 'amend'
            ? AMEND_PRINT_ROUTE_ID
            : INCOMPLETE_PRINT_ROUTE_ID;

    yield put(destroyPrintForm()); // Not redundant -- takes care of object URLS

    if (mode === 'new') {
        yield put(initialize(FORM_NAME, INITIAL_VALUES));
    } else {
        yield put(change(FORM_NAME, 'setupLocked', true));
    }

    yield put(redirectToRoute(routeID));
};

export function* destroyPrintFormWorker() {
    const isRedirected = yield select(isRedirectedSelector);
    if (!isRedirected) {
        // URL blob clean up
        const { attachments, techPack } = yield select(
            formValueSelector(FORM_NAME),
            'attachments',
            'techPack'
        );

        if (attachments)
            attachments.forEach(
                attachment =>
                    attachment &&
                    isBlobUrl(attachment.url) &&
                    URL.revokeObjectURL(attachment.url)
            );
        if (techPack && isBlobUrl(techPack.url))
            URL.revokeObjectURL(techPack.url);

        yield put(destroy(FORM_NAME));
    }

    yield new Promise(resolve => setTimeout(() => resolve(), 0));
    yield put(setRedirect(false));
}

const getVersionsWorker = function*({ payload, meta: { mode } = {} }) {
    // Resets version
    yield put(change(FORM_NAME, 'version', ''));
    if (!payload) return;
    yield put(setVersions({ meta: 'started' }));
    const {
        data: {
            data: [
                {
                    images,
                    artworkSource,
                    retailerId,
                    genderId,
                    ageId,
                    categoryId,
                    department,
                    styleId,
                    description
                } = {}
            ] = []
        } = {}
    } = yield call(apiRequest, {
        url: 'Cad/GetCadByCadRef',
        params: {
            cadRef: payload
        }
    });

    yield put(setVersions({ images }));

    yield put(change(FORM_NAME, 'designSource', artworkSource));

    const { hasFabricOption, hasCopyReference } = yield select(
        formValueSelector(FORM_NAME),
        'hasFabricOption',
        'hasCopyReference'
    );
    if (mode === 'new' && !hasFabricOption && !hasCopyReference) {
        yield put(change(FORM_NAME, 'cadDescription', description));
        yield put(change(FORM_NAME, 'retailer', retailerId));
        yield put(change(FORM_NAME, 'gender', genderId));
        yield put(change(FORM_NAME, 'age', ageId));
        yield put(change(FORM_NAME, 'category', categoryId));
        yield put(change(FORM_NAME, 'department', department));
        yield put(change(FORM_NAME, 'style', styleId));
    }
};

const setPrintRetailerChildrenWorker = function*() {
    yield put(change(FORM_NAME, 'department', ''));
    //yield put(change(FORM_NAME, 'country', ''));
};

const setSpecReferenceChildrenWorker = function*({
    payload: { specReference }
}) {
    // Set description with selector
    const description = yield select(specDescriptionSelector, specReference);
    const reportURL = yield select(specReportURLSelector, specReference);
    yield put(change(FORM_NAME, 'specDescription', description));
    yield put(change(FORM_NAME, 'specReportURL', reportURL));

    const fabrics = yield select(formValueSelector(FORM_NAME), 'fabrics');

    // Do api call to get fabrics
    if (specReference && specReference !== 'N/A') {
        yield put(startSubmit(FORM_NAME));
        yield put(setFabricsLoading(true));

        const { data: { data = [] } = {} } = yield call(apiRequest, {
            url: 'Fabrics/GetFabricBySample',
            params: { sampleRef: specReference }
        });

        const mainBodyExists = yield select(
            fabricForMainBodyExistsSelector,
            FORM_NAME
        );

        const newFabrics = normalizeSpecFabrics(
            data,
            specReference,
            mainBodyExists
        );

        yield put(change(FORM_NAME, 'fabrics', [...fabrics, ...newFabrics]));

        yield put(stopSubmit(FORM_NAME));
        yield put(setFabricsLoading(false));
    } else {
        // Remove the fabrics that have any Fab From
        const filteredFabrics = fabrics.filter(({ fabFrom }) => !fabFrom);
        yield put(change(FORM_NAME, 'fabrics', filteredFabrics));
    }
};

const getCopyPrintDataWorker = function*({ payload: sampleReference }) {
    const hasFabricOption = yield select(
        formValueSelector(FORM_NAME),
        'hasFabricOption'
    );

    if (!hasFabricOption) {
        yield put(change(FORM_NAME, 'hasManualReference', false));
    }

    if (sampleReference) {
        yield put(setLoading(true));

        // Perform the API call
        yield call(
            getReferenceDataWorker,
            sampleReference,
            true,
            hasFabricOption
        );

        if (hasFabricOption) {
            const manualReferenceValue = `${sampleReference}-`;
            yield put(
                change(FORM_NAME, 'manualReference', manualReferenceValue)
            );
        }

        yield put(setLoading(false));
    }
};
const getIncompletePrintDataWorker = function*({ payload: sampleReference }) {
    if (sampleReference) {
        yield put(setLoading(true));

        yield call(getReferenceDataWorker, sampleReference);

        yield put(setLoading(false));
    }
};
const getAmendPrintDataWorker = function*({ payload: sampleReference }) {
    if (sampleReference) {
        yield put(setLoading(true));

        yield call(getReferenceDataWorker, sampleReference);

        yield put(setLoading(false));
    }
};

const setSpecRefAndDescriptionWorker = function*({ payload: { requestType } }) {
    let specRef = '';
    let specDescription = '';
    if (requestType === 1 || requestType === 4) {
        specRef = 'N/A';
        specDescription = 'N/A';
    }
    yield put(change(FORM_NAME, 'specReference', specRef));
    yield put(change(FORM_NAME, 'specDescription', specDescription));
};

// Watchers
export default function*() {
    yield takeLatest(ROUTE_TO_PRINT, routeToPrintWorker);
    yield takeLatest(GET_VERSIONS, getVersionsWorker);
    yield takeLatest(
        SET_PRINT_RETAILER_CHILDREN,
        setPrintRetailerChildrenWorker
    );
    yield takeLatest(
        SET_SPEC_REFERENCE_CHILDREN,
        setSpecReferenceChildrenWorker
    );
    yield takeLatest(DESTROY_PRINT_FORM, destroyPrintFormWorker);
    yield takeLatest(PRINT_PUBLISH, printPublishWorker);
    yield takeLatest(GET_COPY_PRINT_DATA, getCopyPrintDataWorker);
    yield takeLatest(GET_INCOMPLETE_PRINT_DATA, getIncompletePrintDataWorker);
    yield takeLatest(GET_AMEND_PRINT_DATA, getAmendPrintDataWorker);
    yield takeLatest(
        SET_SPEC_REFERENCE_AND_DESCRIPTION,
        setSpecRefAndDescriptionWorker
    );
    yield takeLatest(PRINT_REFERENCE_GENERATE, generatePrintReferenceWorker);
}
