import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
    PieChart as RCPieChart,
    Pie,
    Cell,
    ResponsiveContainer,
    Tooltip,
    Legend
} from 'recharts';
import { ValueLabel } from './PieChartLabels';

export * from './PieChartLabels';
export * from './LegendItem';

const calculateHeight = (outerRadius, contentPadding) => {
    return outerRadius * 2 + contentPadding;
};

const PieChart = memo(
    ({
        width,
        outerRadius,
        data,
        colors,
        labelRenderer: LabelRenderer,
        defaultColor,
        legendRenderer: LegendRenderer,
        contentPadding,
        layout,
        align,
        verticalAlign,
        ...props
    }) => {
        const height = calculateHeight(outerRadius, contentPadding);
        return (
            <ResponsiveContainer width='100%' height={height}>
                <RCPieChart width={width} height={height}>
                    <Pie
                        data={data}
                        label={LabelRenderer}
                        fill={defaultColor}
                        outerRadius={outerRadius}
                        {...props}
                    >
                        {data.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={colors[entry.name] || defaultColor}
                            />
                        ))}
                    </Pie>
                    {LegendRenderer && (
                        <Legend
                            content={LegendRenderer}
                            layout={layout}
                            align={align}
                            verticalAlign={verticalAlign}
                        />
                    )}
                    <Tooltip />
                </RCPieChart>
            </ResponsiveContainer>
        );
    }
);

PieChart.propTypes = {
    width: PropTypes.number,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired
        })
    ).isRequired,
    colors: PropTypes.object,
    labelRenderer: PropTypes.func,
    defaultColor: PropTypes.string,
    cx: PropTypes.string,
    cy: PropTypes.string,
    labelLine: PropTypes.bool,
    outerRadius: PropTypes.number,
    dataKey: PropTypes.string,
    legendRenderer: PropTypes.func,
    contentPadding: PropTypes.number,
    layout: PropTypes.oneOf(['horizontal', 'vertical']),
    align: PropTypes.oneOf(['left', 'center', 'right']),
    verticalAlign: PropTypes.oneOf(['top', 'middle', 'bottom'])
};

PieChart.defaultProps = {
    width: 400,
    data: [],
    colors: {},
    labelRenderer: ValueLabel,
    defaultColor: '#f26831',
    cx: '50%',
    cy: '50%',
    labelLine: false,
    outerRadius: 120,
    innerRadius: 0,
    dataKey: 'value',
    contentPadding: 100,
    layout: 'horizontal',
    align: 'center',
    verticalAlign: 'bottom'
};

export { PieChart as default, PieChart };
