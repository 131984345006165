import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import SelectModel from '@formComponents/SelectModel';
import { datasetLoadingSelector, fetchDatasets } from '@libs/datasets';

import { DOMAIN_NAME } from '../common';
import { PROPERTIES, LICENSORS } from '../redux.datasets';
import { propertiesSelector } from '../redux.selectors';

const propTypes = {
    formname: PropTypes.string.isRequired,
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

const defaultProps = {
    name: 'property',
    label: 'Property',
    placeholder: 'Select property',
    domain: DOMAIN_NAME,
    entity: PROPERTIES,
    disabled: false,
    required: false
};

const mapStateToProps = (state, { formname, domain, entity }) => {
    const licensor = formValueSelector(formname)(state, 'licensor');
    const licensorLoading = datasetLoadingSelector(state, domain, LICENSORS);

    return {
        data: propertiesSelector(state, licensor),
        disabled: !licensor,
        loading:
            licensorLoading || datasetLoadingSelector(state, domain, entity)
    };
};

const mapDispatchToProps = (dispatch, { entity }) => ({
    fetchDataset: () => dispatch(fetchDatasets(entity))
});

const SelectProperties = connect(
    mapStateToProps,
    mapDispatchToProps
)(({ fetchDataset, ...props }) => {
    useEffect(() => {
        fetchDataset();
    }, []);
    return <SelectModel {...props} domain={DOMAIN_NAME} entity={PROPERTIES} />;
});

SelectProperties.propTypes = propTypes;
SelectProperties.defaultProps = defaultProps;

export { SelectProperties as default, SelectProperties };
