import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import {
    datasetSelector,
    datasetLoadingSelector,
    valueSelector,
    fetchDatasets,
    createFetchGridDataset,
    gridDatasetSelector
} from '@libs/datasets';
import { userIdSelector } from 'auth/redux.selectors';

import DataGrid from 'dataGrid';
import { dataGridSettingsSelectorByModule } from 'dataGrid/redux.selectors';
import { setDataGridSettings } from 'dataGrid/redux.actions';

import { FD_GRID_DATA } from 'fabric/redux.datasets';
import { DOMAIN_NAME } from 'fabric/common';
import { MODULE_NAME } from './common';
import { DATASETS, getColumns } from './columns';
import ExportOptions from './components/ExportOptions';

const mapState = state => ({
    storedDataGridSettings: dataGridSettingsSelectorByModule(
        state,
        MODULE_NAME
    ),
    datasetSelector: (domain, entity) => datasetSelector(state, domain, entity),
    datasetLoadingSelector: (domain, entity) =>
        datasetLoadingSelector(state, domain, entity),
    valueSelector: (domain, entity, value) =>
        valueSelector(state, domain, entity, value),
    dataGridLoading: datasetLoadingSelector(state, DOMAIN_NAME, FD_GRID_DATA),
    userId: userIdSelector(state),
    ...gridDatasetSelector(state, DOMAIN_NAME, FD_GRID_DATA)
});

const mapDispatchToProps = dispatch => ({
    fetchGridData: payload =>
        dispatch(
            createFetchGridDataset({
                endpoint: 'FabricDevelopment/GetFabricDevelopmentsViewData',
                domain: DOMAIN_NAME,
                entity: FD_GRID_DATA
            })(payload)
        ),
    fetchDatasets: () => dispatch(fetchDatasets(DATASETS)),
    setDataGridSettings: payload =>
        dispatch(setDataGridSettings(MODULE_NAME)(payload))
});

const FabricDevelopmentView = connect(
    mapState,
    mapDispatchToProps
)(({ fetchDatasets, ...rest }) => {
    const columns = useMemo(() => getColumns(), []);

    useEffect(() => {
        fetchDatasets();
    }, []);

    return (
        <DataGrid
            columns={columns}
            valueSelector={valueSelector}
            rowHeight={50}
            RenderDataGridExportOptions={ExportOptions}
            {...rest}
        />
    );
});

export default FabricDevelopmentView;
