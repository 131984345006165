const normalizeDate = (date = null) => {
    if (date) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        // Format - dd/MM/yyyy
        return new Date(date).toLocaleString('en-GB', options);
    } else return date;
};

// Format - MM/dd/yyyy
const denormalizeDate = date => {
    if (date) {
        const [day, month, year] = date.split('/');
        return [month, day, year].join('/');
    } else return date;
};

const deserializeDate = dateString => {
    if (dateString) {
        // Date string must be in format dd/mm/yyyy;
        const [day, month, year] = dateString.split('/');
        return `${year}-${month}-${day}`;
    } else return '';
};

const reportGenerateDate = () => {
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
        timeZone: 'Europe/London'
    };
    const [date, time] = new Date().toLocaleString('en-GB', options).split(',');
    const [day, month, year] = date.split('/');
    const [hour, mins, sec] = time.trim().split(':');
    return `${year}_${month}_${day}_${hour}_${mins}_${sec}`;
};

const getCurrentFinancialYear = () => {
    let financial_year = '';
    const today = new Date();
    if (today.getMonth() + 1 >= 7) {
        financial_year = today.getFullYear() + '-07-01';
    } else {
        financial_year = today.getFullYear() - 1 + '-07-01';
    }
    return new Date(financial_year);
};

const formatDateToShort = date => {
    if (!date) return '';
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(date).toLocaleString('en-GB', options).replace(/ /g, '-');
};

/* 
On June 16, 2023, we implemented changes in five distinct areas: gender, age, category, department, and style. 
In the Amend and Incomplete modes, users are unable to modify values. 
However, when it comes to historical data, users have the ability to alter the values. 
This function determines whether the user can make changes in such cases.   
*/
const requestedDateAfterNewImplementation = requestedDate => {
    if (!requestedDate) return true;
    const newImplementationDate = new Date('2023-06-17T00:00:00');
    requestedDate = new Date(denormalizeDate(requestedDate));

    return requestedDate >= newImplementationDate;
};

export {
    normalizeDate as default,
    normalizeDate,
    denormalizeDate,
    deserializeDate,
    reportGenerateDate,
    getCurrentFinancialYear,
    formatDateToShort,
    requestedDateAfterNewImplementation
};
