export const generateReferenceSchema = {
    cadRef: 'string',
    cadVersion: 'string',
    graphicDescription: 'string',
    designerFromId: 0,
    retailerId: 0,
    categoryId: 0,
    departmentId: 0,
    genderId: 0,
    styleId: 0,
    ageId: 0,
    countryId: 0,
    copySampleRef: 'string',
    manualRef: 'string',
    userId: 0
};

export const publishSchema = {
    id: 0,
    userId: 0,
    categoryId: 0,
    departmentId: 0,
    genderId: 0,
    styleId: 0,
    ageId: 0,
    sampleRef: 'string',
    developmentNo: 0,
    cadRef: 'string',
    cadVersion: 'string',
    graphicDescription: 'string',
    countryId: 0,
    isDiscontinued: true,
    requestTypeId: 0,
    fitId: 0,
    requestedDate: '2021-01-07T19:33:59.449Z',
    sampleRequiredDate: '2021-01-07T19:33:59.449Z',
    ukSizeId: 0,
    buyerSizeId: 0,
    departmentContactPerson: 'string',
    licensorSizeId: 0,
    isSampleRequired: true,
    isHandover: true,
    specRef: 'string',
    garmentDescription: 'string',
    specComments: 'string',
    artworkLinks: 'string',
    numberOfColours: 0,
    techPackPdfUrl: 'string',
    fabrics: [
        {
            id: 0,
            fabricTypeId: 'string',
            compositionId: 'string',
            gsmId: 'string',
            dyeTypeId: 'string',
            specialFabricRef: 'string',
            specialFinishId: 'string',
            fabricColour: 'string',
            eld: 'string',
            fabricForId: 'string',
            isDischargeable: true,
            isYds: true,
            isEngineeredStripe: true,
            sl: 0,
            fabFrom: 'string',
            istc: true,
            tcText: 'string'
        }
    ],
    boughts: [
        {
            id: 0,
            boughtRef: 'string',
            sl: 0
        }
    ],
    attachments: [
        {
            id: 0,
            sizeOriginal: 'string'
        }
    ]
};
