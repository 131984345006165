import { useMemo } from 'react';

import { LABELS } from '../common';
import { ARTWORK_SOURCE_LICENSED_ID, MAIN_BODY_FABRIC_ID } from '../../common';

const useSchema = (
    mode,
    { hasManualReference, hasCopyReference, designSource },
    isCadMandatory
) => {
    const cadMandatorySchema = useMemo(() => {
        return isCadMandatory
            ? { cad: true, version: true, cadDescription: true }
            : {};
    }, [isCadMandatory]);

    const setupSchema = useMemo(
        () => ({
            ...cadMandatorySchema,
            retailer: true,
            gender: true,
            age: true,
            category: true,
            department: true,
            style: true,
            country: true,
            manualReference: hasManualReference,
            manualReferenceAsync: hasManualReference,
            copyReference: hasCopyReference
        }),
        [hasManualReference, hasCopyReference, cadMandatorySchema]
    );

    const detailsSchema = useMemo(
        () => ({
            dateRequested: true,
            dateRequiredBy: true,
            sendToEcho: true,
            sendToBuyer: true,
            sendToLicensor: designSource === ARTWORK_SOURCE_LICENSED_ID,
            specReference: true,
            specDescription: true,
            fabrics: (fabrics = []) =>
                fabrics.some(
                    ({ fabricFor }) => fabricFor === MAIN_BODY_FABRIC_ID
                )
        }),
        []
    );

    const otherSchema = useMemo(
        () => ({
            numberOfColours: true,
            attachments: (_, fieldError) => !fieldError
        }),
        []
    );

    // Combines
    const publishSchema = useMemo(
        () => ({
            ...setupSchema,
            ...detailsSchema,
            ...otherSchema
        }),
        [setupSchema, detailsSchema, otherSchema]
    );

    return useMemo(
        () => ({
            [LABELS.setup]: setupSchema,
            [LABELS.details]: detailsSchema,
            [LABELS.other]: otherSchema,
            [LABELS.complete]: {},
            publish: publishSchema,
            saveDraft: setupSchema
            // saveDraft: { designer: true, type: true }
        }),
        [mode, setupSchema, detailsSchema, otherSchema, publishSchema]
    );
};

export default useSchema;
