import React from 'react';

import { PieChart } from '@libComponents/Charts';

import Legend from './Legend';
import useDynamicColors from '../hooks';
import NoDataMessage from '../NoDataMessage';

const SampleOverviewChart = ({ data }) => {
    const colors = useDynamicColors(data);

    if (data.length === 0) {
        return <NoDataMessage />;
    }
    return (
        <PieChart
            data={data}
            colors={colors}
            legendRenderer={Legend}
            outerRadius={180}
            innerRadius={55}
            contentPadding={160}
        />
    );
};

export default SampleOverviewChart;
